import { ErrorType } from '../models/HttpError';
import { QueryErrorType } from '../models/FetchErrorModel';
import {
  Calendar,
  Callout,
  Checkbox,
  ChoiceGroup,
  DatePicker,
  DayOfWeek,
  DefaultButton,
  defaultDatePickerStrings,
  DocumentCard,
  DocumentCardActivity,
  DocumentCardStatus,
  DocumentCardTitle,
  Dropdown,
  DropdownMenuItemType,
  FocusTrapZone,
  IBasePickerProps,
  IBasePickerSuggestionsProps,
  IChoiceGroupOption,
  IDocumentCardActivityPerson,
  IDropdownOption,
  IDropdownStyles,
  IInputProps,
  ILabelStyles,
  IPersonaProps,
  IPersonaSharedProps,
  IStackProps,
  IStackStyles,
  IStyleSet,
  Label,
  Link,
  MessageBar,
  MessageBarType,
  NormalPeoplePicker,
  Panel,
  PanelType,
  Pivot,
  PivotItem,
  PrimaryButton,
  Separator,
  Stack,
  TextField,
  Toggle,
  ValidationState
} from '@fluentui/react';

// process http RTK query error
export const HttpQueryErrorHandler = (
  state: any,
  action: any
): QueryErrorType => {
  let CurrentResponse: QueryErrorType;

  if (action.payload) {
    CurrentResponse = {
      RequestId: action.meta.requestId,
      ErrorCode: action.payload.status,
      ErrorMessage:
        action.payload.error ||
        JSON.stringify(action.payload.data) ||
        'error inesperado'
    };
  } else {
    console.log('error', action.error);
    CurrentResponse = {
      RequestId: action.meta.requestId,
      ErrorCode: action.error.code,
      ErrorMessage: action.error.message
    };
  }
  return ErrorQueryDefinitionResponse(CurrentResponse);
};

const ErrorQueryDefinitionResponse = (
  error: QueryErrorType
): QueryErrorType => {
  switch (error.ErrorCode) {
    case 401:
      error.ErrorMessage = 'No Autorizado';
      break;
    case 404:
      error.ErrorMessage = 'Servidor fuera de linea o Recurso no encontrado';
      break;
    case 405:
      error.ErrorMessage = 'Metodo no encontrado al peticionar el recurso';
      break;
    case 409:
      error.ErrorMessage =
        'Ha habido un problema con el servidor. Por favor, reintente más tarde';
      break;
    case 400:
      error.ErrorMessage =
        'Error al procesar la solicitud, verifique la informacion suministrada';
      break;
    case 500:
      error.ErrorMessage =
        'Ocurrio un error inesperado al consultar por el recurso';
      break;
    case 'FETCH_ERROR':
      error.ErrorMessage = 'Servidor fuera de linea o Recurso no encontrado';
      break;
    case 'PARSING_ERROR':
      error.ErrorMessage =
        'Error al procesar la solicitud, verifique la informacion suministrada';
      break;
    case 'TIMEOUT_ERROR':
      error.ErrorMessage = 'Servidor fuera de linea o Recurso no encontrado';
      break;
  }

  return error;
};

// process http error
export const HttpErrorHandler = (
  body: string | null,
  response: any
): ErrorType => {
  console.log('HttpErrorHandler', response);
  let CurrentResponse: ErrorType;
  if (body) {
    const ErrorBody: any = JSON.parse(body);
    console.log('ErrorBody', ErrorBody);
    CurrentResponse = {
      ErrorCode: ErrorBody.statusCode || response.status,
      ErrorMessage: ErrorBody.message || ErrorBody.title || 'error inesperado',
      Errors: ErrorBody.errors
    };

    return ErrorDefinitionResponse(CurrentResponse);
  } else {
    console.log(response);
    console.log(body);
    CurrentResponse = {
      ErrorCode: response.status,
      ErrorMessage: response.statusText,
      Errors: response.errors || []
    };

    return ErrorDefinitionResponse(CurrentResponse);
  }
};

const ErrorDefinitionResponse = (error: ErrorType): ErrorType => {
  switch (error.ErrorCode) {
    case 401:
      error.ErrorMessage = 'No Autorizado';
      break;
    case 404:
      error.ErrorMessage = 'Servidor fuera de linea o Recurso no encontrado';
      break;
    case 405:
      error.ErrorMessage = 'Metodo no encontrado al peticionar el recurso';
      break;
    case 409:
    case 400:
      error.ErrorMessage =
        'Error al procesar la solicitud, verifique la informacion suministrada';
      break;
    case 500:
      error.ErrorMessage =
        'Ocurrio un error inesperado al consultar por el recurso';
      break;
  }

  return error;
};
