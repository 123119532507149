import './login.page.css';
import '../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import ichtysLogo from '../../images/icthysLogo.png';
import { Button, Form, FormSelect } from 'react-bootstrap';
import {
  MessageBar,
  MessageBarType,
  Separator,
  Spinner
} from '@fluentui/react';
import {
  apiSlice,
  useGetPatientTentantsByDocumentQuery
} from '../../redux/slices/apiSlice';
import { useState, ChangeEvent, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/reducer/hooks';
import { MustShowError } from '../../functions/helper';
import { CentersListModal } from './centersListModal';
import { SelectCenterModal } from './selectCenterModal';
import { PatientLogOut } from '../../redux/slices/patientSlice';
import { VerifyTokenExpiration } from '../../redux/slices/patientSlice';

export const LoginPage = () => {
  const navigate = useNavigate();
  const [skip, setSkip] = useState(true);
  const [mySvg, setMySvg] = useState(
    require('../../images/default-login-image.svg').default
  );

  const [patientDocument, setPatientDocument] = useState<string>();
  const [documentType, setDocumentType] = useState<string>('0');

  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const CurrentPatient = useAppSelector((state) => state.patient);
  const [centerModalShow, setCenterModalShow] = useState(false);
  const [selectCenterModalShow, setSelectCenterModalShow] = useState(false);

  const modalRef = useRef(null);

  const [bgImage, setBgImage] = useState('');

  useEffect(() => {
    const getSvgContent = async () => {
      const response = await fetch(
        require('../../images/default-login-image.svg').default
      );
      const svgContent = await response.text();
      const encodedSvg = btoa(svgContent);
      setBgImage(`url("data:image/svg+xml;base64,${encodedSvg}")`);
    };

    getSvgContent();
  }, []);

  useEffect(() => {
    dispatch(VerifyTokenExpiration());
  }, []);

  // useEffect(() => {
  //   if (!CurrentPatient.isTokenExpired) {
  //     navigate('/patient/appointments/');
  //   }
  // }, [CurrentPatient.isTokenExpired]);

  const dispatch = useAppDispatch();

  const {
    data: Tenants,
    error: QueryError,
    isError: isQueryError,
    isSuccess: isQuerySuccess,
    isLoading: isQueryLoading,
    requestId: QueryRequestId
  } = useGetPatientTentantsByDocumentQuery(patientDocument, {
    skip
  });

  useEffect(() => {
    if (CurrentPatient && CurrentPatient.PatientExist === true) {
      if (CurrentPatient.Tentants.length > 1) {
        setSelectCenterModalShow(true);
      } else {
        navigate(
          '/verification/' +
            CurrentPatient.Tentants[0]?.tenantId +
            '/' +
            patientDocument
        );
      }
    }
  }, [CurrentPatient, navigate, documentType, patientDocument]);

  const handlerInputPatientDocument = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    if (
      event.currentTarget.value.length === 8 ||
      event.currentTarget.value.length === 7
    )
      setPatientDocument(event.currentTarget.value);
    else {
      setPatientDocument(undefined);
      setSkip(true);
    }
  };

  const handleDocumentTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setDocumentType(e.target.value);
  };

  const handleKeypress = (e: any) => {
    if (e.key === 'Enter') {
      handlerButtonclick();
    }
  };

  const handlerButtonclick = async () => {
    if (!patientDocument || !documentType) {
      setShowErrorMessage(true);
      return;
    }
    setSkip(false);
  };

  const handlerSelectCenterModalHide = () => {
    setSkip(true);
    setSelectCenterModalShow(false);
    dispatch(apiSlice.util.resetApiState());
    dispatch(PatientLogOut());
  };

  return (
    <div>
      <div
        className="loginbackground"
        style={{
          backgroundImage: `${bgImage}`
        }}
      ></div>
      <div className="loginform custom-container">
        <div className="logos"></div>
        <h2 className="header">¡Bienvenido!</h2>
        <p>Por favor ingrese su DNI para identificarse</p>
        {isQuerySuccess && (!Tenants || Tenants.length < 1) && (
          <div className="roundedBorders">
            <MessageBar
              messageBarType={MessageBarType.error}
              isMultiline={true}
              dismissButtonAriaLabel="Close"
            >
              Usted no se encuentra registrado como paciente en nuestro
              servicio. <br />
              <strong>
                Por favor, haga{''}
                <a
                  href="#"
                  className="primary"
                  onClick={() => setCenterModalShow(true)}
                >
                  click aquí
                </a>{' '}
                para conocer nuestros centros médicos habilitados e iniciar su
                registro en alguno de ellos.
              </strong>
            </MessageBar>
          </div>
        )}
        {MustShowError(CurrentPatient.OperationError, QueryRequestId)}
        {showErrorMessage && (
          <MessageBar messageBarType={MessageBarType.error}>
            Por favor, complete ambos campos.
          </MessageBar>
        )}
        <CentersListModal
          show={centerModalShow}
          onHide={() => setCenterModalShow(false)}
        />
        <SelectCenterModal
          show={selectCenterModalShow}
          onHide={handlerSelectCenterModalHide}
          tenants={CurrentPatient.Tentants}
          patientdocument={patientDocument}
          ref={modalRef} // Agrega el ref a Modal
        />
        <div>
          <Form.Group controlId="documentType" style={{ textAlign: 'center' }}>
            <Form.Label>Tipo de Documento</Form.Label>
            <Form.Select
              aria-label="Default select example"
              onChange={handleDocumentTypeChange}
              size="lg"
            >
              <option value="0">Seleccionar...</option>
              <option value="1">Otro</option>
              <option value="2">Pasaporte</option>
              <option value="3">DNI</option>
            </Form.Select>
          </Form.Group>
          <br />
          <Form.Group
            controlId="formBasicEmail"
            style={{ textAlign: 'center' }}
          >
            <Form.Label>Número de Documento</Form.Label>
            <Form.Control
              required
              type="dni"
              inputMode="numeric"
              style={{ fontSize: 20 }}
              onChange={handlerInputPatientDocument}
              maxLength={8}
              onKeyPress={handleKeypress}
            />
            <Button
              className="btn-default btn-alternative-default btnIngresar btn-custom"
              variant="primary"
              disabled={patientDocument && documentType !== '0' ? false : true}
              type="button"
              onClick={handlerButtonclick}
            >
              {isQueryLoading ? <Spinner /> : 'Ingresar'}
            </Button>
          </Form.Group>
        </div>
        <Separator />
        <label className="TextIchtys">
          Powered by <img className="logo" src={ichtysLogo} alt="Icthys"></img>
        </label>
      </div>
      <h1 className="pageheader custom-header">
        Servicio de <strong>telemedicina</strong>
      </h1>
    </div>
  );
};
