import { Col, Container, Form, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
// import './selectCenterModal.css';
import { useAppSelector } from '../redux/reducer/hooks';
import React, { useState } from 'react';
import warning from '../images/warning-sign.svg';

export interface IProps {
  show: boolean;
  onCancel: () => void;
  onAccept: () => void;
  message: string;
  title: string;
  size: 'sm' | 'lg' | 'xl';
  formComponent?: React.ReactNode;
}

export const GenericAcceptCancelModal = React.forwardRef<
  HTMLDivElement,
  IProps
>((props, ref) => {
  const handleClose = () => {
    props.onCancel();
  };
  return (
    <Modal
      {...props}
      // size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={handleClose}
    >
      <Modal.Header closeButton className="headerblue">
        <Modal.Title id="contained-modal-title-vcenter">
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {' '}
        <div
          style={{
            textAlign: 'center'
          }}
        >
          <img style={{ maxWidth: '90px' }} src={warning} alt="" />
          <br /> <br />
          {props.message}
          <br /> <br />
          {props.formComponent && (
            <>
              {props.formComponent}
              <br />
              <br />
            </>
          )}
        </div>
      </Modal.Body>

      <Modal.Footer style={{ display: 'flex', justifyContent: 'center' }}>
        <div className="appointmentActions today">
          <Button
            variant="outline-success"
            onClick={props.onAccept}
            style={{ flex: 1 }}
          >
            Aceptar
          </Button>
          <Button
            variant="outline-danger"
            onClick={handleClose}
            style={{ flex: 1 }}
          >
            Cancelar
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
});
