import { Doctor } from '../models/DoctorModel';
import { Token } from '../models/Token';
import { IResult } from '../models/HttpResult';
import { ErrorType } from '../models/FetchErrorModel';
import { DoctorAvailability } from '../models/DoctorAvailabilityModel';
import moment from 'moment';

// Functions
import * as Functions from '../functions/common';

const DoctorBaseUrl: string =
  process.env.REACT_APP_CONSULTAMEDICA_API + 'doctors/';

const AppointmentsBaseUrl =
  process.env.REACT_APP_CONSULTAMEDICA_API + '/medicalappointments/';

export const GetDoctorBySearch = async (
  searchcriteria: string
): Promise<Doctor[]> => {
  try {
    const Response: Doctor[] = await fetch(
      DoctorBaseUrl + 'Medicos?SearchText=' + searchcriteria
    )
      .then((response) => {
        switch (response.status) {
          case 200:
            return response.json() as Promise<IResult<Doctor[]>>;
          default:
            return undefined;
        }
      })
      .then((data: IResult<Doctor[]> | undefined) => {
        if (data !== undefined) return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    throw error;
  }
};

export const GetDoctorById = async (doctorid: number): Promise<Doctor> => {
  try {
    const Response: Doctor = await fetch(DoctorBaseUrl + 'Medicos' + doctorid)
      .then((response) => response.json() as Promise<Doctor>)
      .then((data: Doctor) => {
        return data;
      });
    return Response;
  } catch (error: any) {
    throw error;
  }
};

export const GetSpecialties = async (): Promise<string[]> => {
  try {
    const Response: string[] = await fetch(
      DoctorBaseUrl + 'Medicos/specialties'
    )
      .then((response) => response.json() as Promise<string[]>)
      .then((data: string[]) => {
        return data;
      });
    return Response;
  } catch (error: any) {
    throw error;
  }
};

export const GetDoctorCurrentAvailability = async (
  id: number,
  dateRangeInit: Date,
  dateRangeEnd: Date,
  medicalInsuranceId: number,
  medicalInsurancePlanId: number,
  token: string | undefined
): Promise<IResult<DoctorAvailability>> => {
  try {
    const Response: IResult<DoctorAvailability> = await fetch(
      AppointmentsBaseUrl +
        'doctor/currentavailability?Id=' +
        id +
        '&DateRangeInit=' +
        moment(dateRangeInit).format('YYYY-MM-DDThh:mm:ss') +
        '&DateRangeEnd=' +
        moment(dateRangeEnd).add(6, 'month').format('YYYY-MM-DDThh:mm:ss') +
        '&MedicalInsuranceId=' +
        medicalInsuranceId +
        '&MedicalInsurancePlanId=' +
        medicalInsurancePlanId,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        }
      }
    )
      .then(
        (response) => response.json() as Promise<IResult<DoctorAvailability>>
      )
      .then((data: IResult<DoctorAvailability>) => {
        return data;
      });
    return Response;
  } catch (error: any) {
    throw error;
  }
};

export const GetSpecialtyCurrentAvailability = async (
  operationId: number,
  specialty: string,
  dateRangeInit: Date,
  dateRangeEnd: Date,
  medicalInsuranceId: number,
  medicalInsurancePlanId: number,
  token: string | undefined
): Promise<IResult<DoctorAvailability[]>> => {
  try {
    const Response: IResult<DoctorAvailability[]> = await fetch(
      AppointmentsBaseUrl +
        'specialty/currentavailability?Specialty=' +
        specialty +
        '&DateRangeInit=' +
        moment(dateRangeInit).format('YYYY-MM-DDThh:mm:ss') +
        '&DateRangeEnd=' +
        moment(dateRangeEnd).add(6, 'month').format('YYYY-MM-DDThh:mm:ss') +
        '&MedicalInsuranceId=' +
        medicalInsuranceId +
        '&MedicalInsurancePlanId=' +
        medicalInsurancePlanId,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        }
      }
    )
      .then((response) => {
        let Error: ErrorType | undefined = undefined;
        if (response.status >= 400) {
          switch (response.status) {
            case 401:
              Error = {
                OperarionId: operationId,
                ErrorCode: response.status,
                ErrorMesage: 'No Autorizado'
              };
              break;
            case 500:
              Error = {
                OperarionId: operationId,
                ErrorCode: response.status,
                ErrorMesage:
                  'Ocurrio un error inesperado al consultar por el recurso'
              };
              break;
            default:
              Error = {
                OperarionId: operationId,
                ErrorCode: response.status,
                ErrorMesage: 'Error inesperado'
              };
          }
          throw Error;
        } else {
          return response.json() as Promise<IResult<DoctorAvailability[]>>;
        }
      })
      .then((data: IResult<DoctorAvailability[]>) => {
        return data;
      })
      .catch((error: ErrorType) => {
        throw error;
      });
    return Response;
  } catch (error: any) {
    throw error;
  }
};
