import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { initializeIcons } from '@fluentui/react';
import './App.css';

//pages
import { MedicalAppointmentPage } from './pages/appointments/medicalappointments.page';
import { LoginPage } from './pages/login/login.page';
import { VerificationCodePage } from './pages/login/verificationcode.page';
// import { useAppSelector } from './redux/reducer/hooks';
import ProtectedRoute, { ProtectedRouteProps } from './ProtectedRoute';
import { useState } from 'react';
import { Token } from './models/Token';

initializeIcons();

const App = () => {
  // const CurrentPatient = useAppSelector((state) => state.patient);
  const [token] = useState<Token | undefined>(undefined);
  // const token = localStorage.getItem('Authentication');

  const defaultProtectedRouteProps: Omit<ProtectedRouteProps, 'outlet'> = {
    isAuthenticated: !!token,
    authenticationPath: '/'
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route
          path="/verification/:tenantId/:idNumber"
          element={<VerificationCodePage />}
        />
        <Route
          path="patient/appointments/"
          element={
            <ProtectedRoute
              {...defaultProtectedRouteProps}
              outlet={<MedicalAppointmentPage />}
            />
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
