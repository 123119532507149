import { DefaultButton, Spinner } from '@fluentui/react';

import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../redux/reducer/hooks';
import { PatientLogOut } from '../redux/slices/patientSlice';
import { apiSlice } from '../redux/slices/apiSlice';

import { useEffect, useCallback, useState } from 'react';
import { GenericMessageModal } from '../modals/GenericMessageModal';
import { GenericAcceptCancelModal } from '../modals/GenericAcceptCancelModal';

import PatientIcon from '../images/user-square-svgrepo-com.svg';
import DownIcon from '../images/down-arrow-5-svgrepo-com.svg';
import 'bootstrap/dist/css/bootstrap.min.css';

import './userInfoComponent.css';
import { Container, Dropdown, Nav, NavDropdown, Navbar } from 'react-bootstrap';

export const UserInfo = () => {
  const CurrentPatient = useAppSelector((state) => state.patient);
  const [showTokenExpiredModal, setShowTokenExpiredModal] = useState(false);
  const [showLogoutConfirmModal, setShowLogoutConfirmModal] = useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleExit = useCallback(() => {
    dispatch(apiSlice.util.resetApiState());
    dispatch(PatientLogOut());
    navigate('/');
  }, [dispatch, navigate]);

  useEffect(() => {
    if (CurrentPatient.isTokenExpired) {
      setShowTokenExpiredModal(true);
    }
  }, [CurrentPatient.isTokenExpired, handleExit]);

  return (
    <>
      <GenericMessageModal
        show={showTokenExpiredModal}
        message={'Su sesión expiró, por favor vuelva a iniciar sesión.'}
        title={'Sesión expirada'}
        onHide={() => {
          handleExit();
        }}
      />
      <GenericAcceptCancelModal
        show={showLogoutConfirmModal}
        message={'¿Está seguro que desea cerrar sesión?'}
        size="lg"
        title={'Cerrar sesión'}
        onAccept={() => {
          handleExit();
        }}
        onCancel={() => {
          setShowLogoutConfirmModal(false);
        }}
      />

      <div className="UserInfo">
        {/* <DefaultButton
          text="Cerrar sesión"
          onClick={() => {
            setShowLogoutConfirmModal(true);
          }}
        /> */}

        {CurrentPatient.isHCDataloading ? (
          <Spinner label="Cargando..." />
        ) : (
          <>
            <div className="d-flex align-items-center vertical-align-center align-items-start">
              <div className="sm-4" style={{ textAlign: 'center' }}>
                <label>{CurrentPatient?.Patient?.fullName}</label>
                <br />
                <label>
                  <b>
                    {CurrentPatient?.Patient
                      ? CurrentPatient?.Patient.medicalInsurancePlan +
                        ' - ' +
                        CurrentPatient?.Patient.medicalInsurance
                      : ''}
                  </b>
                </label>
              </div>
              <div className="d-flex align-items-center vertical-align-center align-items-start">
                <img
                  src={PatientIcon}
                  alt=""
                  style={{
                    width: '4rem',
                    borderRadius: '50%',
                    marginLeft: '15px'
                  }}
                />
                <Navbar variant="white" bg="white">
                  <Container fluid>
                    <Navbar.Toggle aria-controls="navbar-dark-example" />
                    <Navbar.Collapse id="navbar-dark-example">
                      {/* <Nav> */}
                      <NavDropdown
                        id="nav-dropdown-user-info"
                        title=""
                        menuVariant="white"
                        drop={'down'}
                      >
                        <NavDropdown.Item href="#">Mis datos</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item
                          href="#"
                          onClick={() => {
                            setShowLogoutConfirmModal(true);
                          }}
                        >
                          Cerrar Sesión
                        </NavDropdown.Item>
                      </NavDropdown>
                      {/* </Nav> */}
                    </Navbar.Collapse>
                  </Container>
                </Navbar>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
