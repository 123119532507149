import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

export interface IProps {
  primaryColor: string;
  svgComponent: React.FC<React.SVGProps<SVGSVGElement>>;
}

function CustomImage(props: IProps) {
  const [backgroundImage, setBackgroundImage] = useState('');
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const renderSvg = async () => {
      if (divRef.current) {
        const svgElement = await new Promise<SVGSVGElement>((resolve) => {
          ReactDOM.render(
            <props.svgComponent
              style={{
                fill: props.primaryColor,
                width: '100%'
              }}
            />,
            divRef.current,
            () => resolve(divRef.current?.querySelector('svg') as SVGSVGElement)
          );
        });
        const svgString = divRef.current?.innerHTML;
        const base64String = btoa(svgString);
        const backgroundImageValue = `url("data:image/svg+xml;base64,${base64String}")`;
        setBackgroundImage(backgroundImageValue);
      }
    };
    renderSvg();
  }, [props.primaryColor, props.svgComponent, props]);

  return (
    <div
      className="loginbackground"
      style={{
        backgroundImage: `${backgroundImage}`,
        width: '100%'
      }}
    >
      <div ref={divRef} style={{ display: 'none' }} />
    </div>
  );
}

export default CustomImage;
