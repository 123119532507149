import ichtysLogo from '../../images/icthysLogo.png';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/reducer/hooks';
import cinmeLogo from '../../images/Logocinme.png';
import './login.page.css';
import '../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Form } from 'react-bootstrap';
import PinInput from 'react-pin-input';
import { QueryErrorType } from '../../models/FetchErrorModel';
import { ActionButton, MessageBarType, Label, Spinner } from '@fluentui/react';
import { IIconProps, Separator, MessageBar } from '@fluentui/react';
import {
  useForgotPinMutation,
  useLazyGetMedicalServicesQuery,
  useLazyGetPatientAppointmentsQuery,
  useLazyGetPatientsHCDataQuery,
  useLazyRegeneratePinQuery,
  useVerifyPinMutation
} from '../../redux/slices/apiSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { TenantStyle } from '../../models/TenantStyleModel';
import CustomImage from '../../commons/CustomImage';
import { ReactComponent as CustomLoginImage } from '../../images/custom-login-image.svg';
import { ForgotPinSuccessModal } from './forgotPinSuccessModal';
import { PatientLogOut } from '../../redux/slices/patientSlice';
import { apiSlice } from '../../redux/slices/apiSlice';

export const VerificationCodePage = () => {
  const CurrentPatient = useAppSelector((state) => state.patient);
  const [logo, setLogo] = useState('');
  const { tenantId, idNumber } = useParams();
  const [primaryColor, setPrimaryColor] = useState('#899196');
  const [isForgotPin, setIsForgotPin] = useState(false);
  const [forgotPinSuccessModalShow, setForgotPinSuccessModalShow] =
    useState(false);

  const [pin, setPinInput] = useState<any>();
  const sendEmailIcon: IIconProps = { iconName: 'CircleAddition' };
  const [pinCompleted, setPinCompleted] = useState<boolean>();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleCompleted = async (pin: any) => {
    if (pin.length === 4 && idNumber) {
      // if (pin.length === 4) {
      setPinCompleted(true);
      // setVerifyPinRequest({ patientId: CurrentPatient.Patient.id, pin });
      const data = new FormData();
      if (isForgotPin) {
        forgotPin({
          tenant: tenantId ? tenantId : '',
          idn: idNumber,
          newPin: pin
        });
      } else {
        data.append('grant_type', 'patient_grant');
        data.append('client_id', 'Patients');
        data.append(
          'scope',
          'agendaapi databaseapi IdentityServerApi pre_biller_app'
        );
        data.append('tenant', tenantId ? tenantId : '');
        data.append('idn', idNumber);
        data.append('pin', pin);

        verifyPin(data);
      }
    }
  };

  const handlerForgotPinSuccessModalHide = () => {
    dispatch(apiSlice.util.resetApiState());
    dispatch(PatientLogOut());
    navigate('/');
  };

  const [GetPatientsHCData, { data: Patient, isLoading: isHCDataLoading }] =
    useLazyGetPatientsHCDataQuery();

  const [
    GetPatientAppointments,
    { data: PatientAppointments, isLoading: isGetPatientAppointmentsLoading }
  ] = useLazyGetPatientAppointmentsQuery();

  const [
    verifyPin,
    {
      data: Token,
      error: verifyPinError,
      isError: isVerifyPinError,
      isLoading: isVerifyPinLoading
    }
  ] = useVerifyPinMutation();

  const [
    forgotPin,
    {
      error: forgotPinError,
      isError: isForgotPinError,
      isLoading: isForgotPinLoading,
      isSuccess: isForgotPinSuccess
    }
  ] = useForgotPinMutation();

  const [
    GetMedicalServices,
    { data: MedicalServices, isLoading: isMedicalServicesLoading }
  ] = useLazyGetMedicalServicesQuery();

  useEffect(() => {
    if (isForgotPinSuccess) {
      setForgotPinSuccessModalShow(true);
    }
  }, [isForgotPinSuccess]);

  const [
    RegeneratePin,
    {
      data: isRegenerated,
      isFetching: isRegeneratePinFetching,
      isLoading: isRegeneratePinLoading
    }
  ] = useLazyRegeneratePinQuery();

  const handleRequestNewPin = () => {
    setIsForgotPin(true);
  };
  const handlePatientExist = async () => {
    navigate(`/patient/appointments/`);
  };

  useEffect(() => {
    if (CurrentPatient?.VerificationPinResult) {
      handlePatientExist();
    }
  }, [CurrentPatient?.VerificationPinResult]);

  useEffect(() => {
    if (tenantId) {
      GetTenantStyle(tenantId)
        .then((data: TenantStyle) => {
          if (data !== undefined) {
            document.documentElement.style.setProperty(
              '--primary-color',
              data.primaryColor
            );
            setPrimaryColor(data.primaryColor);
            document.documentElement.style.setProperty(
              '--alternative-color',
              data.alternativeColor
            );

            setLogo(data.logo);
          }
        })
        .catch((error) => {});
    }
  }, [tenantId]);

  const GetTenantStyle = async (tenantId: any): Promise<TenantStyle> => {
    try {
      const Response: TenantStyle = await fetch(
        'https://localhost:44327/Styles/' + tenantId
      )
        .then((response) => {
          switch (response.status) {
            case 200:
              return response.json() as Promise<TenantStyle>;
            default:
              return undefined;
          }
        })
        .then((data: TenantStyle | undefined) => {
          if (data !== undefined) return data;
        })
        .catch((error: any) =>
          error.text().then((body: any) => {
            console.log(error);
          })
        );

      return Response;
    } catch (error: any) {
      throw error;
    }
  };

  const VerificationPinFail = (
    <div className="DivMessage">
      <div className="roundedBorders">
        <MessageBar messageBarType={MessageBarType.error} isMultiline={false}>
          El pin ingresado es incorrecto
        </MessageBar>
      </div>
    </div>
  );

  const SendPinConfirmation = (
    <div className="DivMessage">
      <div className="roundedBorders">
        <MessageBar messageBarType={MessageBarType.success} isMultiline={true}>
          Le hemos enviado un nuevo pin a su casilla de mail
        </MessageBar>
      </div>
    </div>
  );

  return (
    <div>
      <CustomImage
        primaryColor={primaryColor}
        svgComponent={CustomLoginImage}
      />

      <div className="loginform custom-container">
        <div className="logos">
          {logo && (
            <img
              id="dynamicLogo"
              className="logoLogin"
              src={`${logo}`}
              alt="Cinme2"
            />
          )}
          {!logo && (
            <img className="logoLogin" src={cinmeLogo} alt="Cinme"></img>
          )}
        </div>

        {(isRegeneratePinFetching ||
          isVerifyPinLoading ||
          isForgotPinLoading ||
          isHCDataLoading ||
          isMedicalServicesLoading) && <Spinner label="Cargando..." />}
        {CurrentPatient?.SendNewPinSuccess && SendPinConfirmation}
        {CurrentPatient?.VerificationPinResult === false &&
          pinCompleted &&
          VerificationPinFail}
        <ForgotPinSuccessModal
          show={forgotPinSuccessModalShow}
          onHide={handlerForgotPinSuccessModalHide}
        />
        <Form className="formClass">
          {!isForgotPin && (
            <div>
              <h4>Ingrese su pin</h4>
            </div>
          )}
          {isForgotPin && (
            <div>
              <h4>Ingrese el nuevo pin</h4>
            </div>
          )}

          {isVerifyPinError &&
            CurrentPatient.VerificationPinResult !== false &&
            !isRegeneratePinLoading && (
              <div className="roundedBorders">
                <MessageBar
                  messageBarType={MessageBarType.error}
                  isMultiline={true}
                  dismissButtonAriaLabel="Close"
                >
                  Ha ocurrido un problema con la solicitud <br /> <br />
                  <strong>
                    {verifyPinError && (
                      <div>
                        {' '}
                        <ul>
                          {CurrentPatient.OperationError?.filter(
                            (error: QueryErrorType) =>
                              error.RequestId ===
                                CurrentPatient.CurrentRequestId &&
                              error.ErrorCode !== 409
                          ).map((Error: QueryErrorType, index: number) => (
                            <li key={index}>
                              {Error.ErrorCode} - {Error.ErrorMessage}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </strong>
                </MessageBar>
              </div>
            )}
          <PinInput
            length={4}
            initialValue=""
            onChange={(value, index) => {
              if (value.length < 4) {
                setPinCompleted(false);
              } else {
                setPinInput(value);
              }
            }}
            type="numeric"
            inputMode="numeric"
            secret={true}
            style={{ padding: '0px', fontSize: '30px', textAlign: 'center' }}
            inputStyle={{
              borderColor: 'lightgray',
              margin: '3px',
              borderRadius: '10px'
            }}
            inputFocusStyle={{ borderColor: 'gray' }}
            onComplete={(value, index) => handleCompleted(value)}
            autoSelect={true}
            regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
          />
        </Form>
        <Separator />
        <Label>¿Olvidaste tu pin?</Label>
        <ActionButton
          iconProps={sendEmailIcon}
          onClick={handleRequestNewPin}
          allowDisabledFocus
        >
          Crear nuevo pin
        </ActionButton>
        <Separator />
        <label className="TextIchtys">
          Powered by <img className="logo" src={ichtysLogo} alt="Icthys"></img>
        </label>
      </div>
      <h1 className="pageheader custom-header">
        Servicio de <strong>telemedicina</strong>
      </h1>
    </div>
  );
};
