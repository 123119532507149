import { AppThunkAction } from '../reducer';
import { IResult } from '../../models/HttpResult';
import { Doctor } from '../../models/DoctorModel';
import { DoctorAvailability } from './../../models/DoctorAvailabilityModel';

import { ErrorType } from '../../models/FetchErrorModel';

// Actions
import * as DoctorActions from './../actions/doctorActions';

//Services
import * as service from '../../services/Doctor.Services';

type KnownAction =
  | DoctorActions.RequestDoctorsAction
  | DoctorActions.ReceiveDoctorsAction
  | DoctorActions.RequestDoctorAction
  | DoctorActions.ReceiveDoctorAction
  | DoctorActions.ReceiveEmptyDoctorAction
  | DoctorActions.RequestDoctorAvailability
  | DoctorActions.ReceiveDoctorAvailability
  | DoctorActions.RequestSpecialtyAvailability
  | DoctorActions.ReceiveSpecialtyAvailability
  | DoctorActions.OperationFailAction
  | DoctorActions.RequestSpecialtiesAction
  | DoctorActions.ReceiveSpecialtiesAction;

export const actionCreators = {
  RequestDoctors:
    (SearchCriteria: string): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        dispatch({ type: 'REQUEST_DOCTORS', SearchCriteria: SearchCriteria });
        service
          .GetDoctorBySearch(SearchCriteria)
          .then((data: Doctor[] | undefined) => {
            if (data !== undefined)
              dispatch({ type: 'RECEIVE_DOCTORS', Doctors: data });
          })
          .catch((error: ErrorType) =>
            dispatch({ type: 'OPERATION_FAIL', Error: error })
          );
      }
    },
  RequestDoctorById:
    (doctorId: number): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        service
          .GetDoctorById(doctorId)
          .then((data: Doctor) => {
            dispatch({ type: 'RECEIVE_DOCTOR', Doctor: data });
          })
          .catch((error: ErrorType) =>
            dispatch({ type: 'OPERATION_FAIL', Error: error })
          );
      }
    },
  RequestSpecialties:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        dispatch({ type: 'REQUEST_SPECIALTIES' });
        service
          .GetSpecialties()
          .then((data: string[]) => {
            dispatch({ type: 'RECEIVE_SPECIALTIES', Specialties: data });
          })
          .catch((error: ErrorType) =>
            dispatch({ type: 'OPERATION_FAIL', Error: error })
          );
      }
    },
  RequestDoctorCurrentAvailability:
    (
      id: number,
      dateRangeInit: Date,
      dateRangeEnd: Date,
      medicalInsuranceId: number,
      medicalInsurancePlanId: number
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const appState = getState();
      const token = appState.Patient?.Token?.access_token;
      if (appState) {
        dispatch({
          type: 'REQUEST_DOCTOR_AVAILABILITY',
          id: id,
          dateRangeInit: dateRangeInit,
          dateRangeEnd: dateRangeEnd,
          medicalInsuranceId: medicalInsuranceId,
          medicalInsurancePlanId: medicalInsurancePlanId
        });
        service
          .GetDoctorCurrentAvailability(
            id,
            dateRangeInit,
            dateRangeEnd,
            medicalInsuranceId,
            medicalInsurancePlanId,
            token
          )
          .then((data: IResult<DoctorAvailability>) => {
            dispatch({
              type: 'RECEIVE_DOCTOR_AVAILABILITY',
              DoctorAvailability: data.data
            });
          });
      }
    },
  RequestSpecialtyCurrentAvailability:
    (
      operationId: number,
      specialty: string,
      dateRangeInit: Date,
      dateRangeEnd: Date,
      medicalInsuranceId: number,
      medicalInsurancePlanId: number
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const appState = getState();
      const token = appState.Patient?.Token?.access_token;
      if (appState) {
        dispatch({
          type: 'REQUEST_SPECIALTY_AVAILABILITY',
          specialty: specialty,
          dateRangeInit: dateRangeInit,
          dateRangeEnd: dateRangeEnd,
          medicalInsuranceId: medicalInsuranceId,
          medicalInsurancePlanId: medicalInsurancePlanId
        });
        service
          .GetSpecialtyCurrentAvailability(
            operationId,
            specialty,
            dateRangeInit,
            dateRangeEnd,
            medicalInsuranceId,
            medicalInsurancePlanId,
            token
          )
          .then((data: IResult<DoctorAvailability[]>) => {
            dispatch({
              type: 'RECEIVE_SPECIALTY_AVAILABILITY',
              SpecialtyAvailability: data.data
            });
          })
          .catch((error: ErrorType) => {
            dispatch({ type: 'OPERATION_FAIL', Error: error });
          });
      }
    }
};
