import { createSlice } from '@reduxjs/toolkit';
import { DoctorAvailability } from '../../models/DoctorAvailabilityModel';
import { Doctor } from '../../models/DoctorModel';
import { QueryErrorType } from '../../models/FetchErrorModel';
import { apiSlice } from './apiSlice';
import * as Functions from '../../functions/common';
import {
  MedicalPractitionersCoveredResponse,
  PractitionerShiftAvailabilityResponse,
  Practitioner
} from '../../models/PractitionerModel';

export interface DoctorState {
  readonly loading: boolean;
  readonly Doctor: Doctor | undefined; //borrar
  readonly Practitioner: Practitioner | undefined;
  readonly DoctorExist: boolean | undefined; //borrar
  readonly Doctors: Doctor[]; //borrar
  readonly Practitioners: MedicalPractitionersCoveredResponse[];
  readonly SpecialtyAvailability: DoctorAvailability[]; //borrar
  readonly Specialties: string[]; //borrar
  readonly DoctorAvailability: DoctorAvailability | undefined;
  readonly PractitionerAvailability:
    | PractitionerShiftAvailabilityResponse
    | undefined;
  readonly OperationError: QueryErrorType[];
  readonly CurrentRequestId: string | undefined;
}

const initialState: DoctorState = {
  loading: false,
  Doctor: undefined,
  Practitioner: undefined,
  DoctorExist: undefined,
  Doctors: [],
  Practitioners: [],
  Specialties: [],
  SpecialtyAvailability: [],
  DoctorAvailability: undefined,
  PractitionerAvailability: undefined,
  OperationError: [],
  CurrentRequestId: undefined
};

const doctorSlice = createSlice({
  name: 'Doctor',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //REFACTOR, SE UTILIZA PRACTITIONER EN LUGAR DE DOCTOR
      .addMatcher(
        apiSlice.endpoints.getMedicalPractitionersCovered.matchPending,
        (state, action) => {
          state.loading = true;
          state.CurrentRequestId = action.meta.requestId;
        }
      )
      .addMatcher(
        apiSlice.endpoints.getMedicalPractitionersCovered.matchFulfilled,
        (state, action) => {
          state.Practitioners = action.payload.data;
          state.CurrentRequestId = undefined;
        }
      )
      .addMatcher(
        apiSlice.endpoints.getMedicalPractitionersCovered.matchRejected,
        (state, action) => {
          state.Practitioners = [];
          state.OperationError = [
            ...state.OperationError,
            Functions.HttpQueryErrorHandler(state, action)
          ];
          state.loading = false;
        }
      )
      .addMatcher(
        apiSlice.endpoints.getPractitionerShiftAvailability.matchPending,
        (state, action) => {
          state.loading = true;
          state.CurrentRequestId = action.meta.requestId;
        }
      )
      .addMatcher(
        apiSlice.endpoints.getPractitionerShiftAvailability.matchFulfilled,
        (state, action) => {
          state.PractitionerAvailability = action.payload;
          state.CurrentRequestId = undefined;
        }
      )
      .addMatcher(
        apiSlice.endpoints.getPractitionerShiftAvailability.matchRejected,
        (state, action) => {
          state.PractitionerAvailability = undefined;
          state.OperationError = [
            ...state.OperationError,
            Functions.HttpQueryErrorHandler(state, action)
          ];
          state.loading = false;
        }
      )
      //LIMPIAR LUEGO DE CORROBORAR QUE NO SE USAN
      .addMatcher(
        apiSlice.endpoints.getDoctorBySearch.matchPending,
        (state, action) => {
          state.CurrentRequestId = action.meta.requestId;
        }
      )
      .addMatcher(
        apiSlice.endpoints.getDoctorBySearch.matchFulfilled,
        (state, action) => {
          state.Doctors = action.payload;
          state.CurrentRequestId = undefined;
          //   (state.loading = false),
        }
      )
      .addMatcher(
        apiSlice.endpoints.getDoctorBySearch.matchRejected,
        (state, action) => {
          //   state.loading = false;
          state.OperationError = [
            ...state.OperationError,
            Functions.HttpQueryErrorHandler(state, action)
          ];
        }
      )
      .addMatcher(
        apiSlice.endpoints.getDoctorById.matchPending,
        (state, action) => {
          //   state.loading = true;
        }
      )
      .addMatcher(
        apiSlice.endpoints.getDoctorById.matchFulfilled,
        (state, action) => {
          state.Doctor = action.payload;
          state.OperationError = [];
          //   (state.loading = false),
        }
      )
      .addMatcher(
        apiSlice.endpoints.getDoctorById.matchRejected,
        (state, action) => {
          //   state.loading = false;
          state.OperationError = [
            ...state.OperationError,
            Functions.HttpQueryErrorHandler(state, action)
          ];
        }
      )
      .addMatcher(
        apiSlice.endpoints.getDoctorCurrentAvailability.matchPending,
        (state, action) => {
          //   state.loading = true;
        }
      )
      .addMatcher(
        apiSlice.endpoints.getDoctorCurrentAvailability.matchFulfilled,
        (state, action) => {
          state.DoctorAvailability = action.payload.data;
          state.OperationError = [];
          //   (state.loading = false),
        }
      )
      .addMatcher(
        apiSlice.endpoints.getDoctorCurrentAvailability.matchRejected,
        (state, action) => {
          //   state.loading = false;
          state.OperationError = [
            ...state.OperationError,
            Functions.HttpQueryErrorHandler(state, action)
          ];
        }
      )
      .addMatcher(
        apiSlice.endpoints.getSpecialties.matchPending,
        (state, action) => {
          //   state.loading = true;
        }
      )
      .addMatcher(
        apiSlice.endpoints.getSpecialties.matchFulfilled,
        (state, action) => {
          state.Specialties = action.payload;
          state.OperationError = [];
          //   (state.loading = false),
        }
      )
      .addMatcher(
        apiSlice.endpoints.getSpecialties.matchRejected,
        (state, action) => {
          //   state.loading = false;
          state.OperationError = [
            ...state.OperationError,
            Functions.HttpQueryErrorHandler(state, action)
          ];
        }
      );
  }
});

// Extract the action creators object and the reducer
const { actions, reducer } = doctorSlice;
// Extract and export each action creator by name
// export const { VerifyTokenExpiration, PatientLogOut } = actions;
// Export the reducer, either as a default or named export
export default doctorSlice.reducer;
