import { createSlice } from '@reduxjs/toolkit';
import { QueryErrorType } from '../../models/FetchErrorModel';
import { MedicalAppointment } from '../../models/MedicalAppointmentModel';
import { apiSlice } from './apiSlice';
import * as Functions from '../../functions/common';

export interface MedicalAppointmentState {
  //   readonly loading: boolean;
  readonly MedicalAppointments: MedicalAppointment[] | undefined;
  readonly OperationError: QueryErrorType[];
}

const initialState: MedicalAppointmentState = {
  //   loading: false,
  MedicalAppointments: undefined,
  OperationError: []
};

const medicalAppointmentSlice = createSlice({
  name: 'MedicalAppointment',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        apiSlice.endpoints.getMedicalAppointmentsByPatientId.matchPending,
        (state, action) => {
          //   state.loading = true;
        }
      )
      .addMatcher(
        apiSlice.endpoints.getMedicalAppointmentsByPatientId.matchFulfilled,
        (state, action) => {
          //   state.loading = false;
          state.MedicalAppointments = action.payload.data;
        }
      )
      .addMatcher(
        apiSlice.endpoints.getMedicalAppointmentsByPatientId.matchRejected,
        (state, action) => {
          //   state.loading = false;
          state.OperationError = [
            ...state.OperationError,
            Functions.HttpQueryErrorHandler(state, action)
          ];
        }
      )
      .addMatcher(
        apiSlice.endpoints.confirmMedicalAppointment.matchPending,
        (state, action) => {
          //   state.loading = true;
        }
      )
      .addMatcher(
        apiSlice.endpoints.confirmMedicalAppointment.matchFulfilled,
        (state, action) => {
          state.MedicalAppointments = state.MedicalAppointments?.map(
            (medicalappoinment: MedicalAppointment) =>
              medicalappoinment.id === action.payload.data.id
                ? action.payload.data
                : medicalappoinment
          );
        }
      )
      .addMatcher(
        apiSlice.endpoints.confirmMedicalAppointment.matchRejected,
        (state, action) => {
          //   state.loading = false;
          state.OperationError = [
            ...state.OperationError,
            Functions.HttpQueryErrorHandler(state, action)
          ];
        }
      )
      .addMatcher(
        apiSlice.endpoints.cancelMedicalAppointment.matchPending,
        (state, action) => {
          //   state.loading = true;
        }
      )
      .addMatcher(
        apiSlice.endpoints.confirmMedicalAppointment.matchFulfilled,
        (state, action) => {
          state.MedicalAppointments = state.MedicalAppointments?.filter(
            (medicalappoinment: MedicalAppointment) =>
              medicalappoinment.id === action.payload.data.id ? false : true
          );
        }
      )
      .addMatcher(
        apiSlice.endpoints.confirmMedicalAppointment.matchRejected,
        (state, action) => {
          //   state.loading = false;
          state.OperationError = [
            ...state.OperationError,
            Functions.HttpQueryErrorHandler(state, action)
          ];
        }
      )
      .addMatcher(
        apiSlice.endpoints.createMedicalAppointment.matchPending,
        (state, action) => {
          //   state.loading = true;
        }
      )
      .addMatcher(
        apiSlice.endpoints.createMedicalAppointment.matchFulfilled,
        (state, action) => {
          state.MedicalAppointments = state.MedicalAppointments
            ? [...state.MedicalAppointments, action.payload.data]
            : [...[], action.payload.data];
        }
      )
      .addMatcher(
        apiSlice.endpoints.createMedicalAppointment.matchRejected,
        (state, action) => {
          //   state.loading = false;
          state.OperationError = [
            ...state.OperationError,
            Functions.HttpQueryErrorHandler(state, action)
          ];
        }
      );
  }
});

export default medicalAppointmentSlice.reducer;
