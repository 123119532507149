import { createSlice } from '@reduxjs/toolkit';
import { Patient, PatientAppointment } from '../../models/PatientModel';
import { MedicalService } from '../../models/PatientModel';

import { Token } from '../../models/Token';
import { QueryErrorType } from '../../models/FetchErrorModel';
import { apiSlice } from './apiSlice';
import * as Functions from '../../functions/common';
import { Tenant } from '../../models/TenantModel';
import { jwtDecode } from 'jwt-decode';

export interface PatientState {
  readonly loading: boolean;
  readonly isHCDataloading: boolean;
  readonly Patient: Patient | undefined;
  readonly MedicalServices: MedicalService[] | undefined;
  readonly PatientAppointments: PatientAppointment[] | undefined;
  readonly Tentants: Tenant[];
  // readonly SelectedTenantId: string | undefined;
  readonly IdNumber: string | undefined;
  readonly PatientExist: boolean | undefined;
  readonly RequestNewPin: boolean;
  readonly SendNewPinSuccess: boolean;
  readonly SendPinVerification: boolean;
  readonly VerificationPinResult: boolean | undefined;
  readonly Token: Token | undefined;
  readonly OperationError: QueryErrorType[];
  readonly CurrentRequestId: string | undefined;
  readonly isTokenExpired: boolean | undefined;
}

const initialState: PatientState = {
  Tentants: [],
  // SelectedTenantId: undefined,
  IdNumber: undefined,
  loading: false,
  isHCDataloading: false,
  Patient: undefined,
  MedicalServices: undefined,
  PatientExist: undefined,
  RequestNewPin: false,
  SendNewPinSuccess: false,
  SendPinVerification: false,
  VerificationPinResult: undefined,
  Token: undefined,
  OperationError: [],
  CurrentRequestId: undefined,
  PatientAppointments: undefined,
  isTokenExpired: false
};

const patientSlice = createSlice({
  name: 'Patient',
  initialState,
  reducers: {
    PatientLogOut: (state) => {
      localStorage.removeItem('Authentication');
      document.documentElement.style.setProperty('--primary-color', '#899196');
      document.documentElement.style.setProperty(
        '--alternative-color',
        '#20AD9B'
      );
      state = initialState;

      return initialState;
    },
    VerifyTokenExpiration: (state) => {
      const json = localStorage.getItem('Authentication');
      if (json) {
        const token: Token = JSON.parse(json);

        // state.Token = token;
        if (!token || !token.expires_in) {
          // Si no hay token o no tiene información de expiración, consideramos que está expirado.
          state.isTokenExpired = true;
        }
        const decodedToken = jwtDecode(token.access_token);

        if (!decodedToken || !decodedToken || !decodedToken.exp) {
          // No se pudo decodificar o falta información necesaria.
          state.isTokenExpired = true;
        } else {
          // Obtenemos la fecha actual en segundos.
          const currentTime = Math.floor(Date.now() / 1000);

          // Verificamos si la fecha actual está después de la fecha de expiración del token.
          // console.log('currentTime: ' + currentTime);
          // state.isTokenExpired = currentTime > 1720187876;
          state.isTokenExpired = currentTime > decodedToken.exp;
        }
      }
      // } else {
      //   console.log('setting isTokenExpired true');
      //   state.isTokenExpired = true;
      // }
    }
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        apiSlice.endpoints.getPatientAppointments.matchPending,
        (state, action) => {
          state.loading = true;
          state.CurrentRequestId = action.meta.requestId;
        }
      )
      .addMatcher(
        apiSlice.endpoints.getPatientAppointments.matchFulfilled,
        (state, action) => {
          state.PatientAppointments = action.payload.map(
            (appointment: PatientAppointment) => {
              var dateStart: Date = new Date(appointment.start);
              var dateEnd: Date = new Date(appointment.end);
              const timePartsInit = appointment.start
                .toString()
                .split('T')[1]
                .split(':');
              const milisecondsDateInit: number = dateStart.getDate();

              const timePartsEnd = appointment.end
                .toString()
                .split('T')[1]
                .split(':');
              const milisecondsDateEnd: number = dateStart.getDate();

              dateStart?.setUTCHours(Number(timePartsInit[0]));
              dateStart?.setUTCMinutes(Number(timePartsInit[1]));
              dateStart?.setUTCSeconds(Number(timePartsInit[2]));
              dateStart?.setUTCDate(milisecondsDateInit);

              dateEnd?.setUTCHours(Number(timePartsEnd[0]));
              dateEnd?.setUTCMinutes(Number(timePartsEnd[1]));
              dateEnd?.setUTCSeconds(Number(timePartsEnd[2]));
              dateEnd?.setUTCDate(milisecondsDateEnd);

              // Devuelve una nueva instancia de la entidad con las fechas locales
              return {
                ...appointment,
                start: dateStart.toISOString(),
                end: dateEnd.toISOString()
              };
            }
          );
          state.loading = false;
          state.CurrentRequestId = undefined;
        }
      )
      .addMatcher(
        apiSlice.endpoints.getPatientAppointments.matchRejected,
        (state, action) => {
          state.OperationError = [
            ...state.OperationError,
            Functions.HttpQueryErrorHandler(state, action)
          ];
          state.PatientAppointments = [];
          state.loading = false;
        }
      )
      .addMatcher(
        apiSlice.endpoints.checkMedicalServiceCoverageAndPrice.matchPending,
        (state, action) => {
          state.loading = true;
          state.CurrentRequestId = action.meta.requestId;
        }
      )
      .addMatcher(
        apiSlice.endpoints.checkMedicalServiceCoverageAndPrice.matchFulfilled,
        (state, action) => {
          var medicalService = state.MedicalServices?.find(
            (x) => x.id === action.payload.data.service.id
          );
          if (medicalService) {
            medicalService.isCoveredByMedicalInsurance =
              action.payload.data.isCoveredByMedicalInsurance;
            medicalService.price = action.payload.data.price;
          }
          state.loading = false;
          state.CurrentRequestId = undefined;
        }
      )
      .addMatcher(
        apiSlice.endpoints.checkMedicalServiceCoverageAndPrice.matchRejected,
        (state, action) => {
          state.OperationError = [
            ...state.OperationError,
            Functions.HttpQueryErrorHandler(state, action)
          ];
          state.loading = false;
        }
      )
      .addMatcher(
        apiSlice.endpoints.getMedicalServices.matchPending,
        (state, action) => {
          state.loading = true;
          state.CurrentRequestId = action.meta.requestId;
        }
      )
      .addMatcher(
        apiSlice.endpoints.getMedicalServices.matchFulfilled,
        (state, action) => {
          state.MedicalServices = action.payload.data;
          state.loading = false;
          state.CurrentRequestId = undefined;
          state.PatientExist = action.payload ? true : false;
        }
      )
      .addMatcher(
        apiSlice.endpoints.getMedicalServices.matchRejected,
        (state, action) => {
          state.OperationError = [
            ...state.OperationError,
            Functions.HttpQueryErrorHandler(state, action)
          ];
          state.loading = false;
        }
      )
      .addMatcher(
        apiSlice.endpoints.getPatientsHCData.matchPending,
        (state, action) => {
          state.loading = true;
          state.isHCDataloading = true;
          state.CurrentRequestId = action.meta.requestId;
        }
      )
      .addMatcher(
        apiSlice.endpoints.getPatientsHCData.matchFulfilled,
        (state, action) => {
          state.Patient = action.payload;
          state.loading = false;
          state.isHCDataloading = false;
          state.CurrentRequestId = undefined;
          state.PatientExist = action.payload ? true : false;
        }
      )
      .addMatcher(
        apiSlice.endpoints.getPatientsHCData.matchRejected,
        (state, action) => {
          state.OperationError = [
            ...state.OperationError,
            Functions.HttpQueryErrorHandler(state, action)
          ];
          state.loading = false;
          state.isHCDataloading = false;
          state.PatientExist = false;
        }
      )
      .addMatcher(
        apiSlice.endpoints.getPatientTentantsByDocument.matchPending,
        (state, action) => {
          state.loading = true;
          state.IdNumber = action.meta.arg.originalArgs;
          state.CurrentRequestId = action.meta.requestId;
        }
      )
      .addMatcher(
        apiSlice.endpoints.getPatientTentantsByDocument.matchFulfilled,
        (state, action) => {
          //SE DEJA CONFIGURADO CON DATOS DE PRUEBA MULTI TENANT, CUALQUIERA LLEVA A CINME,
          state.Tentants = action.payload; //LULEGO DESCOMENTAR ESTO
          // const tenants: Tenant[] = [
          //   // LUEGO COMENTAR O ELIMINAR ESTO
          //   {
          //     tenantId: 'be752118-9d8b-4aed-9377-dc8551f89a8e',
          //     tenantName: 'CINME'
          //   },
          //   {
          //     tenantId: 'be752118-9d8b-4aed-9377-dc8551f89a8e',
          //     tenantName: 'Sanatorio Frances'
          //   },
          //   {
          //     tenantId: 'be752118-9d8b-4aed-9377-dc8551f89a8e',
          //     tenantName: 'Hospital Privado'
          //   },
          //   {
          //     tenantId: 'be752118-9d8b-4aed-9377-dc8551f89a8e',
          //     tenantName: 'Clínica Reina Fabiola'
          //   }
          // ];
          // state.Tentants = tenants;
          state.loading = false;
          state.CurrentRequestId = undefined;
          state.PatientExist = action.payload.length > 0 ? true : false;
        }
      )
      .addMatcher(
        apiSlice.endpoints.getPatientTentantsByDocument.matchRejected,
        (state, action) => {
          state.OperationError = [
            ...state.OperationError,
            Functions.HttpQueryErrorHandler(state, action)
          ];
          state.loading = false;
          state.PatientExist = false;
          state.IdNumber = undefined;
        }
      )
      .addMatcher(
        apiSlice.endpoints.verifyPin.matchFulfilled,
        (state, action) => {
          localStorage.setItem(
            'Authentication',
            JSON.stringify(action.payload)
          );
          state.SendPinVerification = false;
          state.isTokenExpired = false;
          state.VerificationPinResult = true;
          state.Token = action.payload;
          state.OperationError = [];
        }
      )
      .addMatcher(
        apiSlice.endpoints.verifyPin.matchPending,
        (state, action) => {
          state.SendPinVerification = true;
          state.SendNewPinSuccess = false;
          state.VerificationPinResult = undefined;
          state.CurrentRequestId = action.meta.requestId;
        }
      )
      .addMatcher(
        apiSlice.endpoints.verifyPin.matchRejected,
        (state, action) => {
          state.RequestNewPin = false;
          state.SendPinVerification = false;
          let Error: QueryErrorType = Functions.HttpQueryErrorHandler(
            state,
            action
          );
          if (Error.ErrorCode === 409) {
            //el pin es inválido, no se agrega a la pila de errores
            state.VerificationPinResult = false;
          } else {
            state.VerificationPinResult = undefined;
            state.OperationError = [...state.OperationError, Error];
          }
        }
      )
      .addMatcher(
        apiSlice.endpoints.regeneratePin.matchFulfilled,
        (state, action) => {
          state.RequestNewPin = false;
          state.SendNewPinSuccess = true;
          state.CurrentRequestId = action.meta.requestId;
        }
      )
      .addMatcher(
        apiSlice.endpoints.regeneratePin.matchPending,
        (state, action) => {
          state.RequestNewPin = true;
          state.SendNewPinSuccess = false;
          state.CurrentRequestId = action.meta.requestId;
          state.VerificationPinResult = undefined;
        }
      )
      .addMatcher(
        apiSlice.endpoints.regeneratePin.matchRejected,
        (state, action) => {
          state.RequestNewPin = false;
          state.SendNewPinSuccess = false;
          state.VerificationPinResult = false;
          state.OperationError = [
            ...state.OperationError,
            Functions.HttpQueryErrorHandler(state, action)
          ];
        }
      );
  }
});

// Extract the action creators object and the reducer
const { actions, reducer } = patientSlice;
// Extract and export each action creator by name
export const { VerifyTokenExpiration, PatientLogOut } = actions;
// Export the reducer, either as a default or named export
export default patientSlice.reducer;
