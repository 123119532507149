import {
  MedicalAppointmentResponse,
  MedicalAppointmentsResponse,
  NewMedicalAppointment
} from '../models/MedicalAppointmentModel';

// Functions
import * as Functions from '../functions/common';

const AppointmentsBaseUrl =
  process.env.REACT_APP_CONSULTAMEDICA_API + '/turnosmedicos/';

export const GetMedicalAppointments = async (
  patientId: number,
  token: string | undefined
): Promise<MedicalAppointmentsResponse> => {
  try {
    const Response: MedicalAppointmentsResponse = await fetch(
      AppointmentsBaseUrl + 'patient/' + patientId + '?PageSize=1000',
      {
        method: 'GET',
        mode: 'cors',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        }
      }
    )
      .then((response) => {
        return response.json() as Promise<MedicalAppointmentsResponse>;
      })
      .then((data: MedicalAppointmentsResponse) => {
        return data;
      })
      .catch(
        (error: any) => {
          console.log(error);
          return error;
        }
        // error.text().then((body: any) => {
        //   throw Functions.HttpErrorHandler(body, error);})
      );

    return Response;
  } catch (error: any) {
    throw error;
  }
};

export const ConfirmMedicalappointment = async (
  appointmentId: number
): Promise<MedicalAppointmentResponse> => {
  try {
    const Response: MedicalAppointmentResponse = await fetch(
      AppointmentsBaseUrl + appointmentId + '/confirm'
    )
      .then((response) => {
        return response.json() as Promise<MedicalAppointmentResponse>;
      })
      .then((data: MedicalAppointmentResponse) => {
        return data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    throw error;
  }
};

export const CancelMedicalappointment = async (
  appointmentId: number
): Promise<MedicalAppointmentResponse> => {
  try {
    const Response: MedicalAppointmentResponse = await fetch(
      AppointmentsBaseUrl + appointmentId + '/cancel'
    )
      .then((response) => {
        return response.json() as Promise<MedicalAppointmentResponse>;
      })
      .then((data: MedicalAppointmentResponse) => {
        return data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    throw error;
  }
};

export const CreateMedicalAppointment = async (
  newAppointment: NewMedicalAppointment,
  accessToken: string | undefined
): Promise<MedicalAppointmentResponse> => {
  try {
    const Response: MedicalAppointmentResponse = await fetch(
      AppointmentsBaseUrl,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + accessToken
        },
        body: JSON.stringify(newAppointment)
      }
    )
      .then((response) => {
        return response.json() as Promise<MedicalAppointmentResponse>;
      })
      .then((data: MedicalAppointmentResponse) => {
        return data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    throw error;
  }
};
