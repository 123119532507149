import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import warning from '../../images/warning-sign.svg';
import success from '../../images/success.svg';
import {
  useCancelShiftMutation,
  useLazyGetPatientAppointmentsQuery
} from '../../redux/slices/apiSlice';
import { Spinner } from '@fluentui/react';
import { useAppDispatch } from '../../redux/reducer/hooks';
import { VerifyTokenExpiration } from '../../redux/slices/patientSlice';

interface ICancelAppointmentFormProps {
  show: boolean;
  appointmentId: number;
  onAccept: () => void;
  onClose: () => void;
}

export const CancelAppointmentForm: React.FC<ICancelAppointmentFormProps> = ({
  appointmentId,
  show,
  onAccept,
  onClose
}) => {
  //STATES
  const [reason, setReason] = useState('');
  const [formInvalid, setFormInvalid] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);
  const [selectedOption, setSelectedOption] = useState('');
  const dispatch = useAppDispatch();

  //QUERIES AND MUTATIONS
  const [
    CancelShift,
    { isLoading: isCancelShiftLoading, isSuccess: isCancelShiftSuccess }
  ] = useCancelShiftMutation();
  const [
    GetPatientAppointments,
    { data: PatientAppointments, isLoading: isGetPatientAppointmentsLoading }
  ] = useLazyGetPatientAppointmentsQuery();

  //HANDLERS
  const handleClose = () => {
    setSelectedOption('');
    setReason('');
    setFormInvalid(false);
    onClose();
  };
  const handlerButtonCancelarAppointment = async () => {
    const result = reason === '' ? 'error' : reason;

    await CancelShift({
      eventId: appointmentId,
      reason: result
    }).unwrap();
  };

  const handleAccept = async () => {
    if (
      formRef &&
      formRef.current &&
      formRef?.current?.checkValidity() &&
      selectedOption !== '' &&
      !formInvalid
    ) {
      await handlerButtonCancelarAppointment();
    } else {
      setFormInvalid(true);
    }
  };

  //EFFECTS
  useEffect(() => {
    if (selectedOption !== 'otro') {
      setReason('');
    }
  }, [selectedOption]);

  const isOtherReasonRequired = selectedOption === 'otro';
  return (
    <Modal show={show} onHide={handleClose} size="lg" centered>
      <Modal.Header closeButton className="headerblue">
        <Modal.Title>Cancelación de turno</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          style={{
            textAlign: 'center'
          }}
        >
          {isCancelShiftLoading ? (
            <Spinner label="Cargando..." />
          ) : (
            <>
              {isCancelShiftSuccess ? (
                <>
                  <img style={{ maxWidth: '90px' }} src={success} alt="" />
                  <br /> <br />
                  {'El turno fue cancelado correctamente.'}
                </>
              ) : (
                <>
                  <img
                    style={{ maxWidth: '90px', color: 'green' }}
                    src={warning}
                    alt=""
                  />
                  <br /> <br />
                  {
                    '¿Está seguro que desea cancelar el turno? Si es así seleccione el motivo de su cancelación'
                  }
                  <br /> <br />
                  <Form ref={formRef}>
                    <Form.Group className="mb-3" controlId="reasonRadioGroup">
                      {/* <Form.Label>Motivo</Form.Label> */}
                      <div>
                        <Form.Check
                          type="radio"
                          id="errorCarga"
                          label="Error de carga"
                          name="reasonRadio"
                          checked={selectedOption === 'errorCarga'}
                          onChange={() => {
                            setSelectedOption('errorCarga');
                            setFormInvalid(false);
                          }}
                        />
                        <Form.Check
                          type="radio"
                          id="otro"
                          label="Otro"
                          name="reasonRadio"
                          checked={selectedOption === 'otro'}
                          onChange={() => {
                            setSelectedOption('otro');
                            setFormInvalid(false);
                          }}
                        />
                      </div>
                    </Form.Group>
                    {formInvalid && selectedOption === '' && (
                      <Form.Text style={{ color: '#dc3545' }}>
                        Por favor, seleccione un motivo de cancelación.
                      </Form.Text>
                    )}
                    {isOtherReasonRequired && (
                      <Form.Group className="mb-3" controlId="otherReason">
                        <Form.Label>Otro motivo</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Ingrese otro motivo"
                          onChange={(e) => setReason(e.target.value)}
                          value={reason}
                          required
                          isInvalid={
                            isOtherReasonRequired &&
                            reason === '' &&
                            formInvalid
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Por favor, ingrese un motivo.
                        </Form.Control.Feedback>
                      </Form.Group>
                    )}
                  </Form>
                </>
              )}
            </>
          )}
        </div>
        {/* <img style={{ maxWidth: '90px' }} src={warning} alt="" /> */}
      </Modal.Body>
      <Modal.Footer style={{ display: 'flex', justifyContent: 'center' }}>
        {isCancelShiftSuccess ? (
          <>
            <Button
              variant="outline-success"
              onClick={async () => {
                await GetPatientAppointments({
                  currentDate: new Date(Date.now())
                    .toLocaleString()
                    .split(',')[0],
                  view: 'month'
                });
                handleClose();
              }}
            >
              Aceptar
            </Button>
          </>
        ) : (
          <>
            {' '}
            <Button
              variant="outline-success"
              onClick={() => {
                handleAccept();
              }}
            >
              Aceptar
            </Button>
            <Button variant="outline-danger" onClick={handleClose}>
              Cancelar
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};
