import React, { useState } from 'react';
import { Button, Card } from 'react-bootstrap';

//styles
import './nextMedicalAppointmentComponent.css';

//images
import { PractitionerNameComponent } from './practitionerNameComponent';
import { AppointmentDayComponent } from './appointmentDayComponent';
import { MedicalAppointment } from '../models/MedicalAppointmentModel';
import { Label } from '@fluentui/react';
import { PatientAppointment } from '../models/PatientModel';
import { CancelAppointmentForm } from './Appointment.Form/CancelAppointment.Form.Component';

export interface IProps {
  appointment: PatientAppointment;
}

export const NextMedicAppointmentComponent = (props: IProps) => {
  const [cancelAppointmentModalShow, setCancelAppointmentModalShow] =
    useState(false);
  const handleAccept = () => {
    setCancelAppointmentModalShow(false);
  };

  const handleClose = () => {
    setCancelAppointmentModalShow(false);
  };

  const handlerButtonCancelarAppointment = async () => {
    setCancelAppointmentModalShow(true);
  };

  return (
    <>
      <CancelAppointmentForm
        show={cancelAppointmentModalShow}
        appointmentId={props.appointment.id}
        onAccept={handleAccept}
        onClose={handleClose}
      />
      <Card>
        <Card.Body className="center-content">
          <Card.Header className="headerRed">
            <Label>
              {props.appointment.visitType.code === 'EXTERNAL'
                ? 'PRESENCIAL'
                : 'TELECONSULTA'}
            </Label>
          </Card.Header>
          <Card.Text as="span">
            <div className="d-flex align-items-center justify-content-between align-items-start flex-wrap mt-2">
              <div className="mr-4 ">
                <AppointmentDayComponent
                  Date={new Date(props.appointment.start)}
                />{' '}
              </div>
              <div className="ml-4 ">
                <PractitionerNameComponent appointment={props.appointment} />
              </div>
            </div>
            <div className="appointmentActions">
              <Button
                variant="danger"
                onClick={handlerButtonCancelarAppointment}
              >
                Cancelar
              </Button>
            </div>
          </Card.Text>
        </Card.Body>
      </Card>
    </>
  );
};
