import { Col, Container, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './centerListModal.css';
export interface IProps {
  show: boolean;
  onHide: () => void;
}

export const ForgotPinSuccessModal = (props: IProps) => {
  return (
    <Modal
      className="modal-custom-content"
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Nuevo pin creado
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        className="show-grid modal-body-custom"
        style={{ textAlign: 'center' }}
      >
        <p>
          El nuevo pin se ha configurado correctamente. Vuelva a ingresar sus
          datos con su nuevo pin para acceder.
        </p>
        <br />

        <Button
          className="btn-default btn-alternative-default btnIngresar btn-custom"
          variant="primary"
          type="button"
          onClick={() => {
            props.onHide();
          }}
        >
          {'Aceptar'}
        </Button>
      </Modal.Body>
    </Modal>
  );
};
