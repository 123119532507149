import { Col, Container, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './centerListModal.css';
export interface IProps {
  show: boolean;
  onHide: () => void;
}

export const CentersListModal = (props: IProps) => {
  return (
    <Modal
      className="modal-custom-content"
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Nuevo usuario
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid modal-body-custom">
        <p>
          Para darte de alta como usuario es necesario que te comuniques de
          manera telefónica con alguno de los siguientes centros médicos para
          solicitar un turno. Luego podrás acceder a través de nuestro portal .
        </p>
        <br />
        <h4>Centros médicos habilitados</h4>
        <br />
        <Container>
          <Row>
            <Col xs={6} md={6}>
              CINME
            </Col>
            <Col xs={6} md={6}>
              0800-345-0697
            </Col>
          </Row>
          <Row>
            <Col xs={6} md={6}>
              Sanatorio Allende
            </Col>
            <Col xs={6} md={6}>
              0810-444-0748
            </Col>
          </Row>
          <Row>
            <Col xs={6} md={6}>
              Reina Fabiola
            </Col>
            <Col xs={6} md={6}>
              0810-444-1123
            </Col>
          </Row>
          <Row>
            <Col xs={6} md={6}>
              Sanatorio Francés
            </Col>
            <Col xs={6} md={6}>
              0810-444-7897
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};
