import { Navigate } from 'react-router-dom';

export type ProtectedRouteProps = {
  isAuthenticated: boolean;
  authenticationPath: string;
  outlet: JSX.Element;
};

export default function ProtectedRoute({
  isAuthenticated,
  authenticationPath,
  outlet
}: ProtectedRouteProps) {
  const json = localStorage.getItem('Authentication');
  if (!!json) {
    return outlet;
  } else {
    return <Navigate replace to={{ pathname: authenticationPath }} />;
  }
}
