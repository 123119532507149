import { Button, Card } from 'react-bootstrap';

//styles
import './todayMedicAppointmentComponent.css';

//images
import clock from '../images/clock.svg';
import { PractitionerNameComponent } from './practitionerNameComponent';
import Moment from 'react-moment';
import { useConfirmPatientAppointmentCallMutation } from '../redux/slices/apiSlice';
import { PatientAppointment } from '../models/PatientModel';
import { useState, useEffect } from 'react';
import { CancelAppointmentForm } from './Appointment.Form/CancelAppointment.Form.Component';
import { Spinner } from '@fluentui/react';
import { GenericMessageModal } from '../modals/GenericMessageModal';
import { useAppDispatch } from '../redux/reducer/hooks';
import { VerifyTokenExpiration } from '../redux/slices/patientSlice';

export interface IProps {
  appointment: PatientAppointment;
}

export const TodayMedicAppointmentComponent = (props: IProps) => {
  //STATES
  const dispatch = useAppDispatch();
  const [cancelAppointmentModalShow, setCancelAppointmentModalShow] =
    useState(false);
  const [
    confirmAppointmentErrorModalShow,
    setConfirmAppointmentErrorModalShow
  ] = useState(false);

  //QUERIES AND MUTATIONS
  const [
    ConfirmPatientAppointmentCall,
    {
      isSuccess: isConfirmSuccess,
      isError: isConfirmError,
      isLoading: isConfirmPatientAppointmentCallLoading
    }
  ] = useConfirmPatientAppointmentCallMutation();

  // const [
  //   ConfirmAppointment,
  //   {
  //     data,
  //     isSuccess: isConfirmSuccess,
  //     isError: isConfirmError,
  //     error: confirmError
  //   }
  // ] = useLazyConfirmMedicalAppointmentQuery();

  //HANDLERS
  const handlerButtonStartAppointment = async () => {
    dispatch(VerifyTokenExpiration());
    await ConfirmPatientAppointmentCall({
      eventId: props?.appointment?.id
      // eventId: 999
    }).unwrap();
  };

  useEffect(() => {
    if (isConfirmSuccess) {
      window.open(
        `https://meet.hc.alpha-cr.com/${props?.appointment?.telemedicineToken}/${props?.appointment?.practitioner?.userId}`,
        '_blank',
        'noreferrer'
      );
    }
  }, [isConfirmSuccess]);

  useEffect(() => {
    if (isConfirmError) {
      setConfirmAppointmentErrorModalShow(true);
    }
  }, [isConfirmError]);

  const handlerConfirmAppointmentErrorModalShow = () => {
    setConfirmAppointmentErrorModalShow(false);
  };

  const handlerButtonCancelarAppointment = async () => {
    setCancelAppointmentModalShow(true);
  };
  const handleAccept = () => {
    setCancelAppointmentModalShow(false);
  };

  const handleClose = () => {
    setCancelAppointmentModalShow(false);
  };

  return (
    <>
      <CancelAppointmentForm
        show={cancelAppointmentModalShow}
        appointmentId={props.appointment.id}
        onAccept={handleAccept}
        onClose={handleClose}
      />
      <GenericMessageModal
        show={confirmAppointmentErrorModalShow}
        title="Teleconsulta"
        message="No se encontró el turno"
        onHide={handlerConfirmAppointmentErrorModalShow}
      />
      <Card>
        <Card.Header className="headerblue">
          <label>
            <img src={clock} alt="" />{' '}
            <Moment format="DD [de] MMMM YYYY, HH:mm">
              {props.appointment.start}
            </Moment>
            {' - '}
            <Moment format="HH:mm">{props.appointment.end}</Moment>
          </label>
          <label className="visitType">
            {' '}
            {props.appointment.visitType.code === 'EXTERNAL'
              ? 'PRESENCIAL'
              : 'TELECONSULTA'}
          </label>
        </Card.Header>
        <Card.Body className="center-content">
          <PractitionerNameComponent appointment={props.appointment} />

          <div className="appointmentActions today">
            {isConfirmPatientAppointmentCallLoading ? (
              <Spinner label="Cargando..." />
            ) : (
              <>
                <Button
                  variant="danger"
                  onClick={handlerButtonCancelarAppointment}
                >
                  Cancelar Consulta
                </Button>
                {props.appointment.visitType.code === 'EXTERNAL_PHONE' && (
                  <Button
                    variant="success"
                    onClick={handlerButtonStartAppointment}
                  >
                    Iniciar Consulta
                  </Button>
                )}
              </>
            )}
          </div>
        </Card.Body>
      </Card>
    </>
  );
};
