import { Col, Container, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
// import './selectCenterModal.css';
import { useAppSelector } from '../redux/reducer/hooks';
import React from 'react';

export interface IProps {
  show: boolean;
  onHide: () => void;
  message: string;
  title: string;
}

export const GenericMessageModal = React.forwardRef<HTMLDivElement, IProps>(
  (props, ref) => {
    const handleClose = () => {
      props.onHide(); // Llamamos directamente a onHide para cerrar el modal
    };
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {props.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="show-grid">
          <p>{props.message}</p>
          <br></br>
        </Modal.Body>
      </Modal>
    );
  }
);
