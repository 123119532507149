import {
  AvailableAppointment,
  DoctorAvailableDay
} from '../../models/DoctorAvailabilityModel';
import { Doctor } from '../../models/DoctorModel';
import { PractitionerWithAvailableAppointment } from '../../models/PractitionerAppointmentModel';
import {
  MedicalPractitionersCoveredResponse,
  Practitioner,
  PractitionerAvailableAppointment,
  PractitionerAvailableDay,
  PractitionerShiftAvailabilityResponse,
  PractitionerShifts
} from '../../models/PractitionerModel';
import {
  DoctorAvailableAppointment,
  DoctorShifts
} from './Appointment.Form.Models';

export const GetDisabledDays = (
  appointments: AvailableAppointment[]
): Date[] => {
  const Days: Date[] = (
    appointments.filter((date: AvailableAppointment) =>
      date.available ? false : true
    ) || []
  ).map<Date>(
    (appointment: AvailableAppointment) => new Date(appointment.date)
  );

  return Days;
};

export const GetPractitionerDisabledDays = (
  appointments: PractitionerAvailableAppointment[]
): Date[] => {
  const Days: Date[] = (
    appointments.filter((date: PractitionerAvailableAppointment) =>
      date.available ? false : true
    ) || []
  ).map<Date>(
    (appointment: PractitionerAvailableAppointment) =>
      new Date(appointment.date)
  );

  return Days;
};

export const GetPractitionerAvailableAppointments = (
  date: Date,
  availability: PractitionerShiftAvailabilityResponse | undefined
): PractitionerWithAvailableAppointment | undefined => {
  let doctorsAvailableAppointments: PractitionerWithAvailableAppointment;

  const DateStart: Date | null | undefined = new Date(date);

  if (availability) {
    doctorsAvailableAppointments = availability.availableDates.find(
      (appointmentDate: PractitionerAvailableAppointment) => {
        const appointmentDateStart = new Date(appointmentDate.date);
        return (
          appointmentDateStart.toLocaleDateString() ===
          DateStart?.toLocaleDateString()
        );
      }
    ) as PractitionerWithAvailableAppointment;
    if (doctorsAvailableAppointments.hasOwnProperty('practitioner')) {
      doctorsAvailableAppointments = {
        ...doctorsAvailableAppointments,
        practitioner: availability.practitioner
      };
    } else return doctorsAvailableAppointments;
  } else {
    return undefined;
  }
};

export const GetPractitionerClosestShifts = (
  availability: PractitionerShiftAvailabilityResponse | undefined,
  Practitioners: MedicalPractitionersCoveredResponse[] | undefined
): PractitionerShifts[] => {
  let AvailableShifts: PractitionerShifts[] = [];
  var count: number = 1;

  if (availability && Practitioners) {
    availability.availableDates.forEach(
      (shift: PractitionerAvailableAppointment) => {
        shift.availableAppointments.forEach(
          (appointment: PractitionerAvailableDay, index: number) => {
            if (shift.available && appointment.available && count <= 10) {
              console.log(count);

              const foundPractitioner = Practitioners.find(
                (practitioner: MedicalPractitionersCoveredResponse) =>
                  practitioner.practitionerId ===
                  availability.practitioner.userId
              )?.practitioner;

              AvailableShifts = [
                ...AvailableShifts,
                {
                  practitioner: foundPractitioner,
                  date: shift.date,
                  OnSiteEnabled: appointment.onSiteAvailable,
                  TelemedicineEnabled: appointment.telemedicineAvailable,
                  shiftStart: appointment.appointmentInit,
                  shiftEnd: appointment.appointmentEnd,
                  index: index
                }
              ];
              count++;
            }
          }
        );
      }
    );
  }

  return AvailableShifts;
};

export const GetSpecialtyClosestShifts = (
  availability: DoctorShifts[] | undefined
): DoctorShifts[] => {
  let AvailableShifts: DoctorShifts[] = [];
  var count: number = 1;

  if (availability) {
    availability.forEach((shift: DoctorShifts) => {
      console.log(shift);
      if (count <= 10) {
        AvailableShifts = [...AvailableShifts, shift];
        count++;
      }
    });
  }

  return AvailableShifts;
};

export const GetDoctorShifts = (
  AvailableAppointments: DoctorAvailableAppointment[] | undefined,
  Doctors: Doctor[] | undefined
): DoctorShifts[] => {
  let AvailableShifts: DoctorShifts[] = [];

  if (AvailableAppointments && Doctors) {
    AvailableAppointments.every(
      (availableAppointment: DoctorAvailableAppointment) => {
        console.log(availableAppointment);
        availableAppointment.availableAppointments.forEach(
          (appointment: DoctorAvailableDay, index: number) => {
            if (availableAppointment.available && appointment.available) {
              AvailableShifts = [
                ...AvailableShifts,
                {
                  doctor: Doctors.find((doctor: Doctor) =>
                    doctor.externalDBID === availableAppointment.doctor.id
                      ? true
                      : false
                  ),
                  date: availableAppointment.date,
                  OnSiteEnabled: appointment.onSiteAvailable,
                  TelemedicineEnabled: appointment.telemedicineAvailable,
                  shiftStart: appointment.appointmentInit,
                  shiftEnd: appointment.appointmentEnd,
                  index: index
                }
              ];
            }
          }
        );

        return true;
      }
    );
  }

  console.log(AvailableShifts);

  return AvailableShifts;
};
