import {
  MedicalAppointmentResponse,
  MedicalAppointmentsResponse,
  NewMedicalAppointment
} from '../../models/MedicalAppointmentModel';
import {
  CanceledMedicalAppointmentAction,
  CancelMedicalAppointmentAction,
  ConfirmedMedicalAppointmentAction,
  ConfirmMedicalAppointmentAction,
  CreatedMedicalAppointmentAction,
  CreateMedicalAppointmentAction,
  ReceiveMedicalAppointmentAction,
  RequestMedicalAppointmentAction
} from '../actions/medicalAppointmentsActions';

import { AppThunkAction } from '../reducer';

//Services
import * as service from '../../services/MedicalAppointment.Services';

type KnownAction =
  | RequestMedicalAppointmentAction
  | ReceiveMedicalAppointmentAction
  | ConfirmMedicalAppointmentAction
  | ConfirmedMedicalAppointmentAction
  | CancelMedicalAppointmentAction
  | CreateMedicalAppointmentAction
  | CreatedMedicalAppointmentAction
  | CanceledMedicalAppointmentAction;

export const actionCreators = {
  RequestMedicalAppointments:
    (patientId: number): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const appState = getState();
      const accessToken = appState.Patient?.Token?.access_token;
      if (appState) {
        dispatch({ type: 'REQUEST_MEDICAL_APPOINTMENT', patientId: patientId });
        service
          .GetMedicalAppointments(patientId, accessToken)
          .then((data: MedicalAppointmentsResponse) => {
            dispatch({
              type: 'RECEIVE_MEDICAL_APPOINTMENT',
              MedicalAppointments: data.data
            });
          });
      }
    },
  ConfirmMedicalAppointments:
    (appointmentId: number): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        dispatch({
          type: 'CONFIRM_MEDICAL_APPOINTMENT',
          AppointmentId: appointmentId
        });
        service
          .ConfirmMedicalappointment(appointmentId)
          .then((data: MedicalAppointmentResponse) => {
            dispatch({
              type: 'CONFIRMED_MEDICAL_APPOINTMENT',
              MedicalAppointment: data.data
            });
          });
      }
    },
  CancelMedicalAppointments:
    (appointmentId: number): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        dispatch({
          type: 'CANCEL_MEDICAL_APPOINTMENT',
          AppointmentId: appointmentId
        });
        service
          .CancelMedicalappointment(appointmentId)
          .then((data: MedicalAppointmentResponse) => {
            dispatch({
              type: 'CANCELED_MEDICAL_APPOINTMENT',
              MedicalAppointment: data.data
            });
          });
      }
    },
  CreateMedicalAppointment:
    (newAppointment: NewMedicalAppointment): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const appState = getState();
      const accessToken: string | undefined =
        appState.Patient?.Token?.access_token;
      if (appState) {
        dispatch({
          type: 'CREATE_MEDICAL_APPOINTMENT',
          NewMedicalAppointment: newAppointment
        });
        service
          .CreateMedicalAppointment(newAppointment, accessToken)
          .then((data: MedicalAppointmentResponse) => {
            dispatch({
              type: 'CREATED_MEDICAL_APPOINTMENT',
              MedicalAppointment: data.data
            });
          });
      }
    }
};
