import React from 'react';
import DoctorIcon from '../images/doctor-svgrepo-com.svg';
import { MedicalAppointment } from '../models/MedicalAppointmentModel';

//styles
import './practitionerNameComponent.css';
import { PatientAppointment } from '../models/PatientModel';

export interface IProps {
  appointment: PatientAppointment;
}
export const PractitionerNameComponent = (props: IProps) => {
  return (
    <div
      style={{ textAlign: 'center', marginTop: '15px' }}
      className="practitionerName"
    >
      <img src={DoctorIcon} alt="" style={{ margin: '0 auto' }} />
      <label>
        <strong>{props.appointment.practitioner.speciality}</strong>
        Dr. {props.appointment.practitioner.fullName}
      </label>
    </div>
  );
};
