import { Col, Container, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import './selectCenterModal.css';
import { useAppSelector } from '../../redux/reducer/hooks';
import React from 'react';
import { Tenant } from '../../models/TenantModel';

export interface IProps {
  show: boolean;
  onHide: () => void;
  tenants: Tenant[] | undefined;
  patientdocument: string | undefined;
}

export const SelectCenterModal = React.forwardRef<HTMLDivElement, IProps>(
  (props, ref) => {
    const navigate = useNavigate();

    const onClickTenant = (tenantId: string) => {
      navigate('/verification/' + tenantId + '/' + props.patientdocument);
    };

    const numColumns = 4;

    // Crear la matriz de filas basada en props.tenants
    const rows: Array<Tenant[]> = [];
    let rowIndex = -1;
    let colIndex = 0;
    // Verificar que props.tenants esté definido y sea un array
    if (props.tenants && Array.isArray(props.tenants)) {
      props.tenants.forEach((tenant, index) => {
        if (index % numColumns === 0) {
          rows.push([]);
          rowIndex++;
          colIndex = 0;
        }
        rows[rowIndex][colIndex] = tenant;
        colIndex++;
      });
    }

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Seleccionar centro
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="show-grid">
          <p>Por favor seleccione el centro médico al cual desea acceder.</p>
          <br></br>
          <Container>
            {rows.map((row, rowIndex) => (
              <Row key={rowIndex}>
                {row.map((element, colIndex) => (
                  <Col
                    key={`${rowIndex}-${colIndex}`}
                    className="text-center center-col col-md-3"
                  >
                    <Button
                      className="btnCenterSelection btn-alternative btn-alternative-default btn-custom"
                      variant="primary"
                      type="button"
                      onClick={() => onClickTenant(element.tenantId)}
                    >
                      {element.tenantName}
                    </Button>
                  </Col>
                ))}
              </Row>
            ))}
          </Container>
        </Modal.Body>
      </Modal>
    );
  }
);
