import './appointmentDayComponent.css';
import Moment from 'react-moment';
import 'moment/locale/es';

export interface IProps {
  Date: Date;
}

export const AppointmentDayComponent = (props: IProps) => {
  Moment.globalLocale = 'es';

  return (
    <div className="AppointmentDay">
      <small>
        <Moment format="dddd">{props.Date}</Moment>
      </small>
      <label>
        <Moment format="DD">{props.Date}</Moment>
      </label>
      <small>
        <Moment format="HH:mm">{props.Date}</Moment>
      </small>
    </div>
  );
};
