import {
  Calendar,
  Callout,
  Checkbox,
  ComboBox,
  DefaultButton,
  DocumentCard,
  DocumentCardActivity,
  DocumentCardStatus,
  DocumentCardTitle,
  Dropdown,
  FocusTrapZone,
  IBasePickerSuggestionsProps,
  IComboBox,
  IComboBoxOption,
  IDocumentCardActivityPerson,
  IDropdownOption,
  IDropdownStyles,
  IPersonaProps,
  IStackProps,
  IStackStyles,
  Label,
  MessageBar,
  MessageBarType,
  NormalPeoplePicker,
  Panel,
  PanelType,
  Pivot,
  PivotItem,
  PrimaryButton,
  Spinner,
  Stack,
  ValidationState
} from '@fluentui/react';
import React, { useEffect } from 'react';
import { FormEvent, useState } from 'react';
import './Appointment.Form.Component.css';
import { AppState } from '../../redux/reducer';
import { useDispatch, useSelector, connect } from 'react-redux';
import { NewMedicalAppointment } from '../../models/MedicalAppointmentModel';
import { useBoolean } from '@fluentui/react-hooks';
//Stores
import * as DoctorsStore from '../../redux/middleware/DoctorMiddleware';
import * as MedialAppointmentStore from '../../redux/middleware/MedicalAppointmentMiddleware';
import { Doctor } from '../../models/DoctorModel';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { ErrorType, QueryErrorType } from '../../models/FetchErrorModel';

// functions
import * as Functions from './Appointment.Form.Functions';
import { HourGlassIcon } from '@fluentui/react-icons-mdl2';
import {
  DoctorAvailableAppointment,
  DoctorShifts
} from './Appointment.Form.Models';
import { useAppDispatch, useAppSelector } from '../../redux/reducer/hooks';
import {
  useCheckMedicalServiceCoverageAndPriceMutation,
  useConfirmShiftMutation,
  useLazyGetMedicalPractitionersCoveredQuery,
  useLazyGetMedicalServicesQuery,
  useLazyGetPractitionerShiftAvailabilityQuery
} from '../../redux/slices/apiSlice';
import { MedicalService } from '../../models/PatientModel';
import {
  MedicalPractitionersCoveredResponse,
  Practitioner,
  PractitionerAvailableDay,
  PractitionerShifts
} from '../../models/PractitionerModel';
import {
  NewPractitionerAppointment,
  PractitionerWithAvailableAppointment
} from '../../models/PractitionerAppointmentModel';
import { VerifyTokenExpiration } from '../../redux/slices/patientSlice';

const dropdownStyles: Partial<IDropdownStyles> = {
  dropdown: { width: 250 }
};

const stackTokens = { childrenGap: 50 };
const iconProps = { iconName: 'Calendar' };
const stackStyles: Partial<IStackStyles> = { root: { width: 250 } };
const columnProps: Partial<IStackProps> = {
  tokens: { childrenGap: 15 }
  //styles: { root: { width: 250 } }
};

const onFormatDate = (date?: Date): string => {
  return !date
    ? ''
    : date.getDate().toString().padStart(2, '0') +
        '/' +
        (date.getMonth() + 1).toString().padStart(2, '0') +
        '/' +
        date.getFullYear();
};

interface FormValidation {
  success: boolean;
  errors: string[];
}

const buttonStyles = { root: { marginRight: 8 } };

const DayPickerStrings = {
  months: [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Deciembre'
  ],

  shortMonths: [
    'Ene',
    'Feb',
    'Mar',
    'Abr',
    'May',
    'Jun',
    'Jul',
    'Ago',
    'Sep',
    'Oct',
    'Nov',
    'Dic'
  ],

  days: [
    'Domingo',
    'Lunes',
    'Martes',
    'Miercoles',
    'Jueves',
    'Viernes',
    'Sabado'
  ],

  shortDays: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],

  goToToday: 'Ir a hoy',
  weekNumberFormatString: 'Numero Semana {0}'
};

export interface IProps {
  isOpen: boolean;
  openPanel: () => void;
  dismissPanel: () => void;
  setConfirmShiftSuccessModalShow: (show: boolean) => void;
}

const suggestionProps: IBasePickerSuggestionsProps = {
  suggestionsHeaderText: 'Profesionales sugeridos',
  mostRecentlyUsedHeaderText: 'Profesionales sugeridos',
  noResultsFoundText: 'No se encontraron profesionales',
  loadingText: 'Cargando',
  showRemoveButtons: false,
  suggestionsAvailableAlertText: 'Sugerencia de profesionales habilitada',
  suggestionsContainerAriaLabel: 'Doctores sugeridos'
};

export const AppointmentFormComponent = (props: IProps) => {
  moment.locale();
  const [submittedForm, setSubmittedForm] = useState<boolean>(false);
  const [FormValidationStatus, setFormValidationStatus] = useState<
    FormValidation | undefined
  >();

  const [selectedPractitioner, setSelectedPractitioner] = useState<
    IPersonaProps | undefined
  >();

  const [selectedMedicalService, setSelectedMedicalService] = useState<
    MedicalService | undefined
  >();

  const [medicalServicesOptions, setMedicalServicesOptions] = useState<
    IDropdownOption[]
  >([]);

  const [appointmentFromDate, setAppointmentFromDate] = useState<Date>(
    new Date()
  );
  const [appointmentToDate, setAppointmentToDate] = useState<Date>(new Date());
  const [appointmentSchedule, setAppointmentSchedule] = useState<
    IDropdownOption | undefined
  >();
  const [selectedVisitType, setSelectedVisitType] = useState<
    string | undefined
  >();

  const [errMsg, setErrMsg] = useState<string | undefined>(undefined);

  const [immediatelyAttention, setImmediatelyAttention] =
    useState<boolean>(false);

  const [isFirstTime, setIsFirstTime] = useState<boolean>(false);

  const [showCalendar, { toggle: toggleShowCalendar, setFalse: hideCalendar }] =
    useBoolean(false);
  const buttonContainerRef = React.useRef<HTMLDivElement>(null);

  const [
    dropDownOptionsavailableAppointments,
    setDropDownOptionsavailableAppointments
  ] = useState<IDropdownOption[]>([]);

  const [currentSchedule, setCurrentSchedule] =
    useState<PractitionerAvailableDay>();

  const [
    currentPractitionerAvailabilityDaySelected,
    setCurrentPractitionerAvailabilityDaySelected
  ] = useState<PractitionerWithAvailableAppointment>();

  const [currentAvailabilityDayOptions, setCurrentAvailabilityDayOption] =
    useState<DoctorAvailableAppointment[]>();

  const [disabledDays, setDisabledDays] = useState<Date[]>([]);

  const Doctors = useAppSelector((state) => state.doctor);

  const CurrentPatient = useAppSelector((state) => state.patient);
  const dispatch = useAppDispatch();

  //USE LAZY CALLS
  const [
    GetMedicalServices,
    { data: MedicalServices, isLoading: isMedicalServicesLoading }
  ] = useLazyGetMedicalServicesQuery();

  const [
    checkMedicalServiceCoverageAndPrice,
    {
      data: medicalServiceCoverageAndPrice,
      error: checkMedicalServiceCoverageAndPriceError,
      isError: isCheckMedicalServiceCoverageAndPriceError,
      isLoading: isCheckMedicalServiceCoverageAndPriceLoading
    }
  ] = useCheckMedicalServiceCoverageAndPriceMutation();

  const [
    confirmShift,
    {
      data: confirmShiftDataResponse,
      error: confirmShiftError,
      isError: isConfirmShiftError,
      isLoading: isConfirmShiftLoading,
      isSuccess: isConfirmShiftSuccess
    }
  ] = useConfirmShiftMutation();

  const [
    getMedicalPractitionersCoveredQuery,
    {
      data: Profesionals,
      error: getMedicalPractitionersCoveredQueryError,
      isError: isGetMedicalPractitionersCoveredQueryError,
      isLoading: isGetMedicalPractitionersCoveredQueryLoading
    }
  ] = useLazyGetMedicalPractitionersCoveredQuery();

  const [
    getPractitionerShiftAvailabilityQuery,
    {
      data: PractitionerShiftAvailability,
      error: getPractitionerShiftAvailabilityQueryError,
      isError: isGetPractitionerShiftAvailabilityQueryError,
      isLoading: isGetPractitionerShiftAvailabilityQueryLoading
    }
  ] = useLazyGetPractitionerShiftAvailabilityQuery();

  useEffect(() => {
    const MedicalServicesOptions: IDropdownOption[] =
      CurrentPatient.MedicalServices?.map((medicalService: MedicalService) => ({
        key: medicalService.id.toString(), // La clave debe ser única para cada opción
        text: medicalService.serviceNameReference,
        data: medicalService // Aquí guardamos la entidad completa MedicalService
      })) || [];
    setMedicalServicesOptions(MedicalServicesOptions);
  }, [CurrentPatient.MedicalServices]);

  const onChangeMedicalServiceFieldValue = (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption
  ): void => {
    if (option) {
      ResetForm();
      // Accede a la entidad completa MedicalService desde la propiedad "data" de la opción
      const selectedMedicalService: MedicalService | undefined = option.data;

      if (selectedMedicalService) {
        dispatch(VerifyTokenExpiration());
        setSelectedMedicalService(selectedMedicalService);

        if (
          CurrentPatient.Patient?.medicalInsurance &&
          CurrentPatient.Patient?.medicalInsurancePlan
        ) {
          checkMedicalServiceCoverageAndPrice({
            medicalServiceId: selectedMedicalService.id,
            medicalInsuranceId: parseInt(
              CurrentPatient.Patient?.medicalInsuranceId?.toString()
            ),
            medicalInsurancePlanId: parseInt(
              CurrentPatient.Patient?.medicalInsurancePlanId.toString()
            )
          });
          getMedicalPractitionersCoveredQuery({
            medicalServiceId: selectedMedicalService.id,
            medicalInsuranceId: parseInt(
              CurrentPatient.Patient?.medicalInsuranceId?.toString()
            )
          });
        }
      }
    }
  };

  const CurrentPractitionerAvailability = useAppSelector(
    (state) => state.doctor.PractitionerAvailability
  );

  useEffect(() => {
    dispatch(VerifyTokenExpiration());
    GetMedicalServices(null).unwrap();
  }, []);

  const CreatePractitioner = (practitioner: Practitioner): IPersonaProps => {
    return {
      key: practitioner.userId,
      imageInitials: 'DC',
      text: practitioner.fullName
        ? practitioner.fullName
        : practitioner.fullNameUnique,
      secondaryText: practitioner.speciality,
      id: practitioner.userId.toString()
    };
  };

  const [mostRecentlyUsed, setMostRecentlyUsed] = useState<IPersonaProps[]>([]);
  const [doctorsList, setDoctorsList] = useState<IPersonaProps[]>([]);
  const [practitionersList, setPractitionersList] = useState<IPersonaProps[]>(
    []
  );

  const practitionersPicker = React.useRef(null);

  useEffect(() => {
    setMostRecentlyUsed(
      Doctors?.Practitioners.filter(
        (practitioner: MedicalPractitionersCoveredResponse) =>
          practitioner.practitioner !== null
      ).map((practitioner: MedicalPractitionersCoveredResponse) =>
        CreatePractitioner(practitioner.practitioner)
      ) || []
    );
    setPractitionersList(
      Doctors?.Practitioners.filter(
        (practitioner: MedicalPractitionersCoveredResponse) =>
          practitioner.practitioner !== null
      ).map((practitioner: MedicalPractitionersCoveredResponse) =>
        CreatePractitioner(practitioner.practitioner)
      ) || []
    );
  }, [Doctors]);

  useEffect(() => {
    if (
      CurrentPractitionerAvailability &&
      CurrentPractitionerAvailability.availableDates
    )
      setDisabledDays(
        Functions.GetPractitionerDisabledDays(
          CurrentPractitionerAvailability.availableDates
        )
      );
  }, [CurrentPractitionerAvailability]);

  const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: 300 },
    dropdownOptionText: { overflow: 'visible', whiteSpace: 'normal' },
    dropdownItem: { height: 'auto' }
  };

  const ConstructDropdownPractitionerAppointmentOption = (
    appointment: PractitionerAvailableDay,
    key: number
  ): IDropdownOption => {
    const timePartsInit = appointment.appointmentInit.toString().split(':');
    const timePartsEnd = appointment.appointmentEnd.toString().split(':');
    const DropdownOption: IDropdownOption = {
      key: key,
      text:
        String(parseInt(timePartsInit[0])).padStart(2, '0') +
        ':' +
        String(parseInt(timePartsInit[1])).padStart(2, '0') +
        ' - ' +
        String(parseInt(timePartsEnd[0])).padStart(2, '0') +
        ':' +
        String(parseInt(timePartsEnd[1])).padStart(2, '0') +
        ' | ' +
        (appointment.onSiteAvailable && appointment.telemedicineAvailable
          ? 'Presencial / Telemedicina'
          : '') +
        (!appointment.telemedicineAvailable ? ' Solo presencial' : '') +
        (appointment.telemedicineAvailable && !appointment.onSiteAvailable
          ? ' Solo telemedicina'
          : ''),
      disabled: !appointment.available
    };

    return DropdownOption;
  };

  const onFilterChanged = (
    filterText: string,
    currentPersonas: IPersonaProps[] | undefined,
    limitResults?: number
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    if (filterText) {
      let filteredPersonas: IPersonaProps[] = filterPersonasByText(filterText);
      if (currentPersonas)
        filteredPersonas = removeDuplicates(filteredPersonas, currentPersonas);
      filteredPersonas = limitResults
        ? filteredPersonas.slice(0, limitResults)
        : filteredPersonas;
      return filterPromise(filteredPersonas);
    } else {
      return [];
    }
  };

  const filterPersonasByText = (filterText: string): IPersonaProps[] => {
    const lowerCaseFilter = filterText.toLowerCase();
    return practitionersList.filter((item) =>
      (item.text as string).toLowerCase().includes(lowerCaseFilter)
    );
  };

  const filterPromise = (
    personasToReturn: IPersonaProps[]
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    return convertResultsToPromise(personasToReturn);
  };

  const returnMostRecentlyUsed = (
    currentPersonas: IPersonaProps[] | undefined
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    return filterPromise(removeDuplicates(mostRecentlyUsed, currentPersonas));
  };

  const onRemoveSuggestion = (item: IPersonaProps): void => {
    const indexPeopleList: number = doctorsList.indexOf(item);
    const indexMostRecentlyUsed: number = mostRecentlyUsed.indexOf(item);

    if (indexPeopleList >= 0) {
      const newPeople: IPersonaProps[] = doctorsList
        .slice(0, indexPeopleList)
        .concat(doctorsList.slice(indexPeopleList + 1));
      setDoctorsList(newPeople);
    }

    if (indexMostRecentlyUsed >= 0) {
      const newSuggestedPeople: IPersonaProps[] = mostRecentlyUsed
        .slice(0, indexMostRecentlyUsed)
        .concat(mostRecentlyUsed.slice(indexMostRecentlyUsed + 1));
      setMostRecentlyUsed(newSuggestedPeople);
    }
  };

  const [immediateShifts, setImmediateShifts] = useState<DoctorShifts[]>([]);
  const [practitionerImmediateShifts, setPractitionersImmediateShifts] =
    useState<PractitionerShifts[]>([]);

  const onRenderFooterContent = () => {
    return (
      <div>
        <PrimaryButton onClick={HandleSaveForm} styles={buttonStyles}>
          Confirmar
        </PrimaryButton>
        <DefaultButton onClick={HandleCancelForm}>Cancelar</DefaultButton>
      </div>
    );
  };

  const ValidateAttribute = (): boolean => {
    var validationResult: boolean = true;

    var FormValidation: FormValidation = { success: true, errors: [] };
    setFormValidationStatus(FormValidation);

    if (!selectedPractitioner?.key) {
      validationResult = false;
      FormValidation.errors.push(
        'Debe seleccionar el Médico con el cual desea solicitar el turno'
      );
    }

    if (!currentSchedule) {
      validationResult = false;
      FormValidation.errors.push('Debe seleccionar un turno');
    }

    if (!selectedVisitType) {
      validationResult = false;
      FormValidation.errors.push(
        'Debe seleccionar si desea un turno presencial o por teleconsulta'
      );
    }

    FormValidation.success = validationResult;
    setFormValidationStatus(FormValidation);
    return validationResult;
  };

  const HandleSaveForm = async () => {
    setSubmittedForm(true);
    if (ValidateAttribute() && CurrentPatient) {
      var PractitionerAppointmentValues: NewPractitionerAppointment;

      PractitionerAppointmentValues = {
        start: appointmentFromDate,
        end: appointmentToDate,
        practitionerId: selectedPractitioner?.id || '',
        visitTypeCode: selectedVisitType || 'EXTERNAL',
        desc: '',
        location: '',
        telemedicineToken: uuidv4()
      };
      await confirmShift(PractitionerAppointmentValues);
    }
  };

  useEffect(() => {
    if (
      !isConfirmShiftLoading &&
      confirmShiftDataResponse &&
      isConfirmShiftSuccess
    ) {
      ResetForm();
      props.dismissPanel();
      props.setConfirmShiftSuccessModalShow(true);
    }
  }, [isConfirmShiftLoading, confirmShiftDataResponse, isConfirmShiftSuccess]);

  useEffect(() => {
    if (confirmShiftError) {
      // 2) Checking if error is FetchBaseQueryError based on
      // discriminated property 'status':
      if ('status' in confirmShiftError && 'data' in confirmShiftError) {
        // you can access all properties of `FetchBaseQueryError` here
        const data = JSON.stringify(confirmShiftError.data);
        const parsedData = JSON.parse(data);
        if ('error' in parsedData) {
          if (
            parsedData.error === 'calendarErrors.Event_Create_StartLowerThanNow'
          ) {
            setErrMsg(
              'No se puede confirmar el turno. El horario de inicio ya ha pasado.'
            );
          }
        }
      }
    }
  }, [confirmShiftError]);

  const HandleCancelForm = () => {
    ResetForm();
    props.dismissPanel();
  };

  const ResetForm = () => {
    setSubmittedForm(false);
    setSelectedVisitType(undefined);
    setAppointmentFromDate(new Date());
    setAppointmentToDate(new Date());
    setAppointmentSchedule(undefined);
    setFormValidationStatus(undefined);
    setDropDownOptionsavailableAppointments([]);
    setImmediatelyAttention(false);
    setSelectedVisitType(undefined);
    setCurrentSchedule(undefined);
    setCurrentAvailabilityDayOption(undefined);
    setImmediateShifts([]);
    setSelectedPractitioner(undefined);
    setCurrentPractitionerAvailabilityDaySelected(undefined);
    setIsFirstTime(false);
    props.setConfirmShiftSuccessModalShow(false);
    setErrMsg(undefined);
  };

  const SelectPractitioner = async (
    practitioners: IPersonaProps[] | undefined
  ) => {
    if (practitioners && practitioners.length > 0) {
      setSelectedPractitioner(practitioners[0]);
      await getPractitionerShiftAvailabilityQuery({
        medicalServiceId: selectedMedicalService?.id || 0,
        medicalInsuranceId: parseInt(
          CurrentPatient?.Patient?.medicalInsuranceId?.toString() || '0',
          10
        ),
        medicalInsurancePlanId: parseInt(
          CurrentPatient?.Patient?.medicalInsurancePlanId?.toString() || '0',
          10
        ),
        userId: practitioners[0].key?.toString() || '',
        isFirstTime: isFirstTime,
        dateRangeInit: moment(new Date())
          .format('YYYY-MM-DDThh:mm:ss')
          .toString(),
        dateRangeEnd: moment(new Date())
          .add(6, 'month')
          .format('YYYY-MM-DDThh:mm:ss')
          .toString()
      });
    } else {
      ResetForm();
    }
  };

  const _onIsFirstTimeChecked = async (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    isChecked?: boolean
  ) => {
    setIsFirstTime(isChecked!);
    setAppointmentFromDate(new Date());
    setAppointmentToDate(new Date());
    setCurrentPractitionerAvailabilityDaySelected(undefined);
    setCurrentSchedule(undefined);
    setImmediatelyAttention(false);
    await getPractitionerShiftAvailabilityQuery({
      medicalServiceId: selectedMedicalService?.id || 0,
      medicalInsuranceId: parseInt(
        CurrentPatient?.Patient?.medicalInsuranceId?.toString() || '0',
        10
      ),
      medicalInsurancePlanId: parseInt(
        CurrentPatient?.Patient?.medicalInsurancePlanId?.toString() || '0',
        10
      ),
      userId: selectedPractitioner?.key?.toString() || '',
      isFirstTime: isChecked!,
      dateRangeInit: moment(new Date())
        .format('YYYY-MM-DDThh:mm:ss')
        .toString(),
      dateRangeEnd: moment(new Date())
        .add(6, 'month')
        .format('YYYY-MM-DDThh:mm:ss')
        .toString()
    });
  };

  const SelectVisitType = (
    event: FormEvent<HTMLDivElement>,
    item: IDropdownOption | undefined
  ): void => {
    setSelectedVisitType(String(item?.key) || 'EXTERNAL');
  };

  const onChangePractitionerAppointmentDateFieldValue = (
    date: Date | null | undefined
  ) => {
    if (date) {
      // by doctor
      const PractitionerDayAvailability:
        | PractitionerWithAvailableAppointment
        | undefined = Functions.GetPractitionerAvailableAppointments(
        date,
        CurrentPractitionerAvailability
      );

      if (
        PractitionerDayAvailability &&
        PractitionerDayAvailability.availableAppointments
      ) {
        const list =
          PractitionerDayAvailability.availableAppointments.map<IDropdownOption>(
            (appointment: PractitionerAvailableDay, index: number) =>
              ConstructDropdownPractitionerAppointmentOption(appointment, index)
          );

        setCurrentPractitionerAvailabilityDaySelected(
          PractitionerDayAvailability
        );
        setDropDownOptionsavailableAppointments(list || []);
      }

      if (date !== appointmentFromDate) {
        setAppointmentFromDate(date || new Date());
        setAppointmentToDate(date || new Date());
      }
      // }
      hideCalendar();
    }
  };

  const onChangeAppointmentTimeFieldValue = (
    event: FormEvent<HTMLDivElement>,
    item: IDropdownOption | undefined
  ): void => {
    setAppointmentSchedule(item);
    if (item && currentPractitionerAvailabilityDaySelected) {
      const schedule: PractitionerAvailableDay | undefined =
        currentPractitionerAvailabilityDaySelected.availableAppointments[
          Number(item.key)
        ];

      setCurrentSchedule(schedule);

      if (!schedule.telemedicineAvailable) setSelectedVisitType('EXTERNAL');
      if (!schedule.onSiteAvailable && schedule.telemedicineAvailable)
        setSelectedVisitType('EXTERNAL_PHONE');

      var date: Date = new Date(appointmentFromDate);
      if (date) {
        const DateStart: Date | null | undefined = new Date(date);
        const DateEnd: Date | null | undefined = new Date(date);

        const timePartsInit = schedule.appointmentInit.toString().split(':');
        const timePartsEnd = schedule.appointmentEnd.toString().split(':');

        DateStart?.setHours(Number(timePartsInit[0]));
        DateStart?.setMinutes(Number(timePartsInit[1]));
        DateStart?.setSeconds(Number(timePartsInit[2]));

        DateEnd?.setHours(Number(timePartsEnd[0]));
        DateEnd?.setMinutes(Number(timePartsEnd[1]));
        DateEnd?.setSeconds(Number(timePartsEnd[2]));

        setAppointmentFromDate(DateStart || new Date());
        setAppointmentToDate(DateEnd || new Date());
      }
    }
  };

  const onSelectPractitionerClosedAppointmentTimeFieldValue = (
    item: PractitionerShifts,
    index: number
  ): void => {
    if (item) {
      let PractitionerAvailabilityDay:
        | PractitionerWithAvailableAppointment
        | undefined = undefined;

      PractitionerAvailabilityDay =
        Functions.GetPractitionerAvailableAppointments(
          item.date,
          CurrentPractitionerAvailability
        );

      if (PractitionerAvailabilityDay) {
        const schedule: PractitionerAvailableDay | undefined =
          PractitionerAvailabilityDay.availableAppointments[item.index];
        if (schedule) {
          setCurrentSchedule(schedule);

          if (!schedule.telemedicineAvailable) setSelectedVisitType('EXTERNAL');
          if (!schedule.onSiteAvailable && schedule.telemedicineAvailable)
            setSelectedVisitType('EXTERNAL_PHONE');

          const CurrentShiftSelected: IDropdownOption =
            ConstructDropdownPractitionerAppointmentOption(
              schedule,
              item.index
            );

          setAppointmentSchedule(CurrentShiftSelected);
          setCurrentPractitionerAvailabilityDaySelected(
            PractitionerAvailabilityDay
          );

          var date: Date = new Date(item.date);
          if (date) {
            const DateStart: Date | null | undefined = new Date(date);
            const DateEnd: Date | null | undefined = new Date(date);

            const timePartsInit = schedule.appointmentInit
              .toString()
              .split(':');
            const timePartsEnd = schedule.appointmentEnd.toString().split(':');

            DateStart?.setHours(Number(timePartsInit[0]));
            DateStart?.setMinutes(Number(timePartsInit[1]));
            DateStart?.setSeconds(Number(timePartsInit[2]));

            DateEnd?.setHours(Number(timePartsEnd[0]));
            DateEnd?.setMinutes(Number(timePartsEnd[1]));
            DateEnd?.setSeconds(Number(timePartsEnd[2]));

            setAppointmentFromDate(DateStart || new Date());
            setAppointmentToDate(DateEnd || new Date());
          }
        }
      }
    }
  };

  const _onCheckedChange2 = (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    isChecked?: boolean
  ) => {
    setCurrentPractitionerAvailabilityDaySelected(undefined);
    setCurrentSchedule(undefined);
    setImmediatelyAttention(isChecked!);
    if (!isChecked) {
      setCurrentAvailabilityDayOption(undefined);
      setCurrentSchedule(undefined);
      setSelectedVisitType(undefined);
      setPractitionersImmediateShifts([]);
    } else {
      setPractitionersImmediateShifts(
        Functions.GetPractitionerClosestShifts(
          CurrentPractitionerAvailability,
          Doctors?.Practitioners
        )
      );
    }
  };

  const VisitOptions: IDropdownOption[] = [
    { key: 'EXTERNAL', text: 'De forma presencial' },
    { key: 'EXTERNAL_PHONE', text: 'Remoto por Telemedicina' }
  ];

  const GetMaxDate = (): Date => {
    var maxDate: Date = new Date();
    maxDate.setMonth(new Date().getMonth() + 6);
    return maxDate;
  };

  const formFields = (
    <Stack {...columnProps}>
      <label>Cobertura médica seleccionada :</label>
      <b>
        {CurrentPatient?.Patient
          ? CurrentPatient?.Patient.medicalInsurancePlan +
            ' - ' +
            CurrentPatient?.Patient.medicalInsurance
          : ''}
      </b>

      <Pivot>
        <PivotItem
          key="1"
          headerText="Buscar"
          headerButtonProps={{
            'data-order': 1,
            'data-title': 'My Files Title'
          }}
          className="mt-2"
        >
          {!isMedicalServicesLoading && (
            <ComboBox
              placeholder="Seleccione un servicio médico"
              label="Servicio médico"
              options={medicalServicesOptions}
              onChange={onChangeMedicalServiceFieldValue}
              styles={dropdownStyles}
              errorMessage={
                submittedForm === true && !selectedMedicalService
                  ? 'Debe seleccionar un servicio médico'
                  : ''
              }
              required
              allowFreeform // Esto permite a los usuarios escribir y buscar opciones
              autoComplete="on" // Esto habilita la autocompletación mientras se escribe
              useComboBoxAsMenuWidth // Alinea el ancho del menú desplegable con el cuadro de texto
            />
          )}

          {medicalServiceCoverageAndPrice &&
            !isCheckMedicalServiceCoverageAndPriceLoading &&
            !medicalServiceCoverageAndPrice.data
              .isCoveredByMedicalInsurance && (
              <MessageBar
                messageBarType={MessageBarType.warning}
                isMultiline={true}
                className="mt-3"
              >
                El servicio que seleccionó no está cubierto por su obra social o
                prepaga. Tendrá que pagar el valor de la prestación el día del
                turno.
              </MessageBar>
            )}
          {medicalServiceCoverageAndPrice &&
            !isCheckMedicalServiceCoverageAndPriceLoading && (
              <>
                <Label>Profesional</Label>
                <NormalPeoplePicker
                  onResolveSuggestions={onFilterChanged}
                  onEmptyResolveSuggestions={returnMostRecentlyUsed}
                  getTextFromItem={getTextFromItem}
                  pickerSuggestionsProps={suggestionProps}
                  className={'ms-PeoplePicker'}
                  key={'normal'}
                  onRemoveSuggestion={onRemoveSuggestion}
                  onValidateInput={validateInput}
                  selectionAriaLabel={'Selected contacts'}
                  removeButtonAriaLabel={'Remove'}
                  itemLimit={1}
                  inputProps={{
                    onBlur: (ev: React.FocusEvent<HTMLInputElement>) =>
                      console.log('onBlur called'),
                    onFocus: (ev: React.FocusEvent<HTMLInputElement>) =>
                      console.log('onFocus called'),
                    'aria-label': 'Profesional'
                  }}
                  componentRef={practitionersPicker}
                  onInputChange={onInputChange}
                  onChange={SelectPractitioner}
                />
                <br />
              </>
            )}

          {CurrentPractitionerAvailability?.availableWeekDays.length === 0 &&
            selectedPractitioner && (
              <MessageBar
                messageBarType={MessageBarType.warning}
                isMultiline={true}
                className="mt-3"
              >
                El médico seleccionado no tiene disponibilidad
              </MessageBar>
            )}
        </PivotItem>
      </Pivot>
      {selectedPractitioner &&
        CurrentPractitionerAvailability?.availableWeekDays.length !== 0 && (
          <>
            <Checkbox
              label="¿Es tu primer consulta con este médico?"
              checked={isFirstTime}
              onChange={_onIsFirstTimeChecked}
            />

            <Label>Fecha</Label>
            {immediatelyAttention === false && (
              <>
                <div ref={buttonContainerRef}>
                  <DefaultButton
                    onClick={toggleShowCalendar}
                    className="normalbutton"
                    text={
                      !currentPractitionerAvailabilityDaySelected
                        ? 'Fecha de atención'
                        : currentAvailabilityDayOptions ||
                          currentPractitionerAvailabilityDaySelected
                        ? new Date(
                            currentPractitionerAvailabilityDaySelected!.date
                          ).toLocaleDateString('fr-BE')
                        : 'Fecha de atención'
                    }
                  />
                </div>
                {showCalendar && (
                  <Callout
                    isBeakVisible={false}
                    gapSpace={0}
                    doNotLayer={false}
                    target={buttonContainerRef}
                    onDismiss={hideCalendar}
                    setInitialFocus
                  >
                    <FocusTrapZone isClickableOutsideFocusTrap>
                      <Calendar
                        strings={DayPickerStrings}
                        onSelectDate={
                          onChangePractitionerAppointmentDateFieldValue
                        }
                        restrictedDates={disabledDays}
                        minDate={new Date()}
                        maxDate={GetMaxDate()}
                        isMonthPickerVisible={true}
                        value={appointmentFromDate}
                      />
                    </FocusTrapZone>
                  </Callout>
                )}
              </>
            )}
            <Checkbox
              label="Necesito ser atendido lo antes posible"
              checked={immediatelyAttention}
              onChange={_onCheckedChange2}
            />
          </>
        )}

      {dropDownOptionsavailableAppointments.length > 0 &&
        currentPractitionerAvailabilityDaySelected &&
        !immediatelyAttention && (
          <>
            {console.log('render horario')}
            <Dropdown
              placeholder="Seleccione una opción"
              label="Horario"
              options={dropDownOptionsavailableAppointments}
              onChange={onChangeAppointmentTimeFieldValue}
              styles={dropdownStyles}
              errorMessage={
                submittedForm === true && !currentSchedule
                  ? 'Debe seleccionar un horario para la consulta'
                  : undefined
              }
              required
            />
          </>
        )}
      {immediatelyAttention && (
        <>
          <label className="ms-label">Próximos turnos disponibles</label>
        </>
      )}
      {immediatelyAttention && (
        <div className="ImmediateShiftsRoll">
          {practitionerImmediateShifts.map(
            (shifts: PractitionerShifts, index: number) => (
              <>
                {console.log('render card')}
                <DocumentCard
                  aria-label="Lunes 17 19:30 a 20:00"
                  key={shifts.index}
                  className={
                    appointmentSchedule?.key === shifts.index ? 'Active' : ''
                  }
                  onClick={() =>
                    onSelectPractitionerClosedAppointmentTimeFieldValue(
                      shifts,
                      index
                    )
                  }
                >
                  <DocumentCardTitle
                    title={moment(shifts.date).format('dddd DD MMMM')}
                    shouldTruncate
                    showAsSecondaryTitle
                  />
                  <DocumentCardTitle
                    title={
                      String(
                        shifts.shiftStart.toString().split(':')[0]
                      ).padStart(2, '0') +
                      ':' +
                      String(
                        shifts.shiftStart.toString().split(':')[1]
                      ).padStart(2, '0') +
                      ' - ' +
                      String(shifts.shiftEnd.toString().split(':')[0]).padStart(
                        2,
                        '0'
                      ) +
                      ':' +
                      String(shifts.shiftEnd.toString().split(':')[1]).padStart(
                        2,
                        '0'
                      ) +
                      ''
                    }
                    shouldTruncate
                  />
                  <DocumentCardStatus
                    statusIcon=""
                    status={
                      (shifts.OnSiteEnabled && shifts.TelemedicineEnabled
                        ? 'Presencial / Telemedicina'
                        : '') +
                      (!shifts.TelemedicineEnabled ? ' Solo presencial' : '') +
                      (shifts.TelemedicineEnabled && !shifts.OnSiteEnabled
                        ? ' Solo telemedicina'
                        : '')
                    }
                  />
                  <DocumentCardActivity
                    activity={shifts.practitioner?.externalSpecialty || ''}
                    people={[
                      {
                        name:
                          shifts.practitioner?.fullName +
                            ', ' +
                            shifts.practitioner?.userName || '',
                        profileImageSrc: '',
                        initials: 'DC'
                      }
                    ]}
                  />
                </DocumentCard>
              </>
            )
          )}
        </div>
      )}
      {currentSchedule &&
        currentSchedule.onSiteAvailable &&
        currentSchedule.telemedicineAvailable && (
          <Dropdown
            placeholder="Seleccione una opción"
            label="¿Cómo desea realizar su consulta?"
            options={VisitOptions}
            onChange={SelectVisitType}
            // styles={dropdownStyles}
            errorMessage={
              submittedForm === true && !selectedVisitType
                ? 'Debe seleccionar un tipo de visita'
                : undefined
            }
            required
            selectedKey={selectedVisitType}
          />
        )}
      {(isCheckMedicalServiceCoverageAndPriceLoading ||
        CurrentPatient.loading ||
        isGetPractitionerShiftAvailabilityQueryLoading ||
        isMedicalServicesLoading) && (
        <>
          <br />
          <Spinner label="Cargando..." />
        </>
      )}
      {isConfirmShiftLoading && (
        <>
          <br />
          <br />
          <br />
          <br />
          <Spinner label="Cargando..." />
        </>
      )}
    </Stack>
  );

  return (
    <>
      <Panel
        isOpen={props.isOpen}
        onDismiss={HandleCancelForm}
        headerText="Nuevo Turno"
        isLightDismiss
        type={PanelType.smallFixedFar}
        closeButtonAriaLabel="Close"
        onRenderFooterContent={onRenderFooterContent}
        // Stretch panel content to fill the available height so the footer is positioned
        // at the bottom of the page
        isFooterAtBottom={false}
      >
        {submittedForm && !FormValidationStatus?.success && (
          <div className="roundedBorders">
            <MessageBar
              messageBarType={MessageBarType.error}
              isMultiline={true}
            >
              Revise la información suministrada:
              <ul>
                {FormValidationStatus?.errors.map(
                  (Error: string, index: number) => (
                    <li key={index}>{Error}</li>
                  )
                )}
              </ul>
            </MessageBar>
          </div>
        )}
        {isCheckMedicalServiceCoverageAndPriceError && (
          <div className="roundedBorders">
            <MessageBar
              messageBarType={MessageBarType.error}
              isMultiline={true}
            >
              Ha ocurrido un error al intentar obtener la cobertura del servicio
              seleccionado. Pruebe nuevamente en unos momentos o contáctese con
              el administrador.
            </MessageBar>
          </div>
        )}
        {isGetMedicalPractitionersCoveredQueryError && (
          <div className="roundedBorders">
            <MessageBar
              messageBarType={MessageBarType.error}
              isMultiline={true}
            >
              Ha ocurrido un error al intentar obtener los profesionales
              habilitados para el servicio seleccionado. Pruebe nuevamente en
              unos momentos o contáctese con el administrador.
            </MessageBar>
          </div>
        )}
        {isGetPractitionerShiftAvailabilityQueryError && (
          <div className="roundedBorders">
            <MessageBar
              messageBarType={MessageBarType.error}
              isMultiline={true}
            >
              Ha ocurrido un error al intentar obtener los turnos del
              profesional seleccionado. Pruebe nuevamente en unos momentos o
              contáctese con el administrador.
            </MessageBar>
          </div>
        )}
        {submittedForm &&
          FormValidationStatus?.success &&
          isConfirmShiftError &&
          confirmShiftError && (
            <div className="roundedBorders">
              <MessageBar
                messageBarType={MessageBarType.error}
                isMultiline={true}
              >
                {' '}
                {errMsg ? (
                  errMsg
                ) : (
                  <>
                    Ha ocurrido un error al intentar confirmar su turno. Pruebe
                    nuevamente en unos momentos o contáctese con el
                    administrador.{' '}
                  </>
                )}
              </MessageBar>
            </div>
          )}

        {formFields}
      </Panel>
    </>
  );
};

function removeDuplicates(
  personas: IPersonaProps[],
  possibleDupes: IPersonaProps[] | undefined
) {
  if (possibleDupes)
    return personas.filter(
      (persona) => !listContainsPersona(persona, possibleDupes)
    );
  else return personas;
}

function listContainsPersona(
  persona: IPersonaProps,
  personas: IPersonaProps[]
) {
  if (!personas || !personas.length || personas.length === 0) {
    return false;
  }
  return personas.filter((item) => item.text === persona.text).length > 0;
}

function convertResultsToPromise(
  results: IPersonaProps[]
): Promise<IPersonaProps[]> {
  return new Promise<IPersonaProps[]>((resolve, reject) =>
    setTimeout(() => resolve(results), 500)
  );
}

function getTextFromItem(persona: IPersonaProps): string {
  return persona.text as string;
}

function validateInput(input: string): ValidationState {
  if (input.indexOf('@') !== -1) {
    return ValidationState.valid;
  } else if (input.length > 1) {
    return ValidationState.warning;
  } else {
    return ValidationState.invalid;
  }
}

function onInputChange(input: string): string {
  return input;
}

const mapStateToProps = (state: AppState) => ({
  ...state.MedicalAppointments,
  ...state.Doctors
});

const mapDispatchToProps = {
  ...MedialAppointmentStore.actionCreators,
  ...DoctorsStore.actionCreators
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppointmentFormComponent as any);
