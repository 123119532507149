import React from 'react';
import { UserInfo } from './userinfoComponent';
import LogoAlpha from '../images/LogoAlpha.png';

import './Header.css';

interface Props {
  Title: string;
}

export const Header = (props: Props) => (
  <header className="header-container">
    <div className="logo-container">
      <img src={LogoAlpha} alt="Icthys S.A." />
    </div>
    <div style={{ display: 'contents' }}>
      <div className="title-container" style={{ flex: 1 }}>
        <label>{props.Title}</label>
      </div>
      <div className="userInfo-container">
        <UserInfo />
      </div>
    </div>
  </header>
);
