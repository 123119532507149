import { useEffect, useState } from 'react';
import { NextMedicAppointmentComponent } from '../../components/nextMedicalAppointmentComponent';
import { TodayMedicAppointmentComponent } from '../../components/todayMedicAppointmentComponent';
import '../appointments/medicalappointments.css';
import { useNavigate } from 'react-router-dom';
import { MedicalAppointment } from '../../models/MedicalAppointmentModel';
import noAppointments from '../../images/noAppointment.png';
import { IIconProps, Label, PrimaryButton, Spinner } from '@fluentui/react';
import { AppointmentFormComponent } from '../../components/Appointment.Form/Appointment.Form.Component';
import { useBoolean } from '@fluentui/react-hooks';
import { useAppDispatch, useAppSelector } from '../../redux/reducer/hooks';
import {
  useLazyGetPatientAppointmentsQuery,
  useLazyGetPatientsHCDataQuery
} from '../../redux/slices/apiSlice';
import { VerifyTokenExpiration } from '../../redux/slices/patientSlice';
import { Header } from '../../commons/pageHeaderComponent';
import { PatientAppointment } from '../../models/PatientModel';
import { GenericMessageModal } from '../../modals/GenericMessageModal';

export const MedicalAppointmentPage = () => {
  const dispatch = useAppDispatch();
  const CurrentPatient = useAppSelector((state) => state.patient);

  //USE STATES
  const [TodayPatientAppointments, setTodayPatientAppointments] =
    useState<PatientAppointment[]>();
  const [NextPatientAppointments, setNextPatientAppointments] =
    useState<PatientAppointment[]>();
  const [confirmShiftSuccessModalShow, setConfirmShiftSuccessModalShow] =
    useState(false);
  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] =
    useBoolean(false);

  //QUERIES AND MUTATIONS USES
  const [
    GetPatientAppointments,
    { data: PatientAppointments, isLoading: isGetPatientAppointmentsLoading }
  ] = useLazyGetPatientAppointmentsQuery();
  const [GetPatientsHCData, { data: Patient, isLoading: isHCDataLoading }] =
    useLazyGetPatientsHCDataQuery();

  //USE EFFECTS
  useEffect(() => {
    dispatch(VerifyTokenExpiration());
    GetPatientAppointments({
      currentDate: new Date(Date.now()).toISOString(),
      view: 'month'
    });
  }, []);

  useEffect(() => {
    dispatch(VerifyTokenExpiration());
    GetPatientsHCData(null).unwrap();
  }, []);

  const isSameDay = (date1: Date, date2: Date): boolean =>
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate();

  useEffect(() => {
    if (CurrentPatient?.PatientAppointments) {
      const now = new Date();
      setTodayPatientAppointments(
        CurrentPatient.PatientAppointments.filter(
          (appointment: PatientAppointment) => {
            const appointmentDate = new Date(appointment.start);
            return isSameDay(appointmentDate, now);
          }
        )
      );
      setNextPatientAppointments(
        CurrentPatient.PatientAppointments?.filter(
          (appointment: PatientAppointment) =>
            new Date(appointment.start).getDate() > now.getDate()
        )
      );
    }
  }, [CurrentPatient?.PatientAppointments]);

  useEffect(() => {
    if (CurrentPatient.isTokenExpired) {
      dismissPanel();
    }
  }, [CurrentPatient.isTokenExpired, dismissPanel]);

  //HANDLERS
  const HandleAddAppointmentClick = () => {
    openPanel();
  };
  const handlerConfirmShiftSuccessModalHide = () => {
    dispatch(VerifyTokenExpiration());
    GetPatientAppointments({
      currentDate: new Date(Date.now()).toISOString(),
      view: 'month'
    });
    setConfirmShiftSuccessModalShow(false);
  };

  return (
    <>
      {
        <GenericMessageModal
          show={confirmShiftSuccessModalShow}
          title="Turno confirmado"
          message="El turno se registró exitosamente"
          onHide={handlerConfirmShiftSuccessModalHide}
        />
      }
      <Header Title="Mis turnos - Telemedicina" />
      <div className="misTurnosPage">
        <div className="PageHeader">
          <div className="button-container">
            <PrimaryButton
              text="Nuevo Turno"
              iconProps={addIcon}
              onClick={HandleAddAppointmentClick}
              allowDisabledFocus
            />
          </div>
        </div>
        <br />
        <br />
        <div className="TurnosList">
          <div className="List">
            <h5>
              <strong>Turnos para hoy</strong>
            </h5>
            <br />
            <br />
            {isGetPatientAppointmentsLoading ? (
              <Spinner label="Cargando..." />
            ) : (
              <>
                {TodayPatientAppointments?.map(
                  (appointment: PatientAppointment, index: number) => (
                    <TodayMedicAppointmentComponent
                      key={appointment.id || index}
                      appointment={appointment}
                    />
                  )
                )}
              </>
            )}
            {TodayPatientAppointments?.length === 0 &&
              !isGetPatientAppointmentsLoading && (
                <div className="noAppoinments">
                  <img
                    src={noAppointments}
                    className="relaxImage"
                    alt="no hay turnos para hoy"
                  />
                  <Label>No tiene agendado turnos para el día de hoy.</Label>
                </div>
              )}
          </div>
          <div className="List large">
            <h5>
              <strong>Próximos turnos</strong>
            </h5>
            <br />
            <br />
            {isGetPatientAppointmentsLoading ? (
              <Spinner label="Cargando..." />
            ) : (
              <>
                {NextPatientAppointments?.map(
                  (appointment: PatientAppointment, index: number) => (
                    <NextMedicAppointmentComponent
                      key={appointment.id || index}
                      appointment={appointment}
                    />
                  )
                )}
              </>
            )}
            {NextPatientAppointments?.length === 0 &&
              !isGetPatientAppointmentsLoading && (
                <div className="noAppoinments">
                  <img
                    src={noAppointments}
                    className="relaxImage"
                    alt="no hay turnos para hoy"
                  />
                  <Label>No tiene agendado turnos próximamente.</Label>
                </div>
              )}
          </div>
        </div>
      </div>
      <AppointmentFormComponent
        isOpen={isOpen}
        dismissPanel={dismissPanel}
        openPanel={openPanel}
        setConfirmShiftSuccessModalShow={setConfirmShiftSuccessModalShow}
      />
    </>
  );
};

const addIcon: IIconProps = { iconName: 'Add' };
