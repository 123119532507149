import { configureStore, combineReducers } from '@reduxjs/toolkit';
import patientReducer from '../slices/patientSlice';
import medicalAppointmentReducer from '../slices/medicalAppointmentSlice';
import doctorReducer from '../slices/doctorSlice';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import { createBrowserHistory, History } from 'history';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import { apiSlice } from '../slices/apiSlice';

export const history = createBrowserHistory();

const rootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    patient: patientReducer,
    medicalAppointment: medicalAppointmentReducer,
    doctor: doctorReducer,
    [apiSlice.reducerPath]: apiSlice.reducer
  });

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      routerMiddleware(history),
      apiSlice.middleware
    ]),
  reducer: rootReducer(history)
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
