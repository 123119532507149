import { MessageBar, MessageBarType } from '@fluentui/react';
import { QueryErrorType } from '../models/FetchErrorModel';

export const MustShowError = (
  errors: QueryErrorType[],
  requestId: string | undefined
): JSX.Element => {
  return (
    <>
      {errors.find(
        (error: QueryErrorType) => error.RequestId === requestId
      ) && (
        <div className="roundedBorders">
          <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
            Ocurrio un error:
            <br />
            <ul>
              {errors
                ?.filter(
                  (error: QueryErrorType) => error.RequestId === requestId
                )
                .map((Error: QueryErrorType, index: number) => (
                  <li key={index}>{Error.ErrorMessage}</li>
                ))}
            </ul>
          </MessageBar>
        </div>
      )}
    </>
  );
};
